import { useEffect } from "react";
import { useDispatch } from "react-redux";
import getGroups from "../../../../ajax/actions/group/groups_get";

const GroupWorker = () => {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGroups());
  }, []);
  return null;
};

export default GroupWorker;
