import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./style.scss"
const ModalDialog = ({ title, content,show, handleClose,size, className, hasFooter=false  }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size={size || 'lg'}
      className={className}
      >
        <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {content}
        </Modal.Body>

      {hasFooter && <Modal.Footer>
        <Button variant="secondary">Close</Button>
        
      </Modal.Footer>}
      </Modal>
  )
}

export default ModalDialog