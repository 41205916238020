import React from "react";
import useMouseDown from "../../hooks/useMouseDown";
import TextField from "./TextField";

const NewSelect = (props) => {
  const {
    label,
    name,
    value,
    options,
    error,
    touched,
    onChange,
    onAddonHandleSubmit,
    onAddonChange,
    addonValue,
    addonName,
  } = props;
  const [show, setShow] = React.useState(false);
  let errorsPresent = error && error.length;

  const container = React.useRef();

  const onClickOutside = (e) => {
    if (container.current.contains(e.target)) return;
    setShow(false);
  };

  useMouseDown(onClickOutside);

  const _value = React.useMemo(() => {
    if (options.length) {
      const foundItem = options.find((item) => item.value === value);
      return foundItem ? foundItem.label : null;
    }
    return null;
  }, [options, value]);

  const onOptionChange = (value) => {
    onChange(value);
    setShow(false);
  };
  return (
    <>
      {errorsPresent && touched ? (
        <span className="error-message">{`${label} ${error}.`}</span>
      ) : null}
      <div
        className={`custom-select-container ${show ? "active" : ""}`}
        ref={container}
      >
        <div className="selected-field" onClick={() => setShow(!show)}>
          <div className="selected-category">
            {value ? _value : "Välj Kategori"}
          </div>
          <span className="icon-up-arrow"></span>
        </div>

        {show && (
          <div className="dropdown-container-custom">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Name new category"
                aria-label=""
                name={addonName}
                value={addonValue}
                onChange={onAddonChange}
                aria-describedby="basic-addon1"
              />

              <div className="input-group-append">
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={onAddonHandleSubmit}
                >
                  Add
                </button>
              </div>
            </div>

            <ul>
              {options.length
                ? options?.map((option) => {
                    return (
                      <li
                        key={option.value}
                        onClick={() => onOptionChange(option.value)}
                      >
                        {option.label}
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default NewSelect;
