export default function putCategoryReducer
  (state = { error:'', success:'', processing:false}, action)
{
  switch (action.type) {
    case 'PUT_CATEGORY_SUCCESS':
      return {...state, ...{success: action.success}};

    case 'PUT_CATEGORY_ERROR':
      return {...state, ...{error: action.error}};

    case 'PUT_CATEGORY_PROCESSING':
      return {...state, ...{processing: action.processing}};

    case 'PUT_CATEGORY_RESET':
      return { ...state, ...{
          success: null,
          error: null,
          processing:false
        }
      };

    default: return state;
  }
}