import React, { memo } from "react";
import { names, ucFirst, unslufigy } from "../../../utils/StringUtils";
import Div from "../Div/Div";
import GrowingLoader from "../loaders/GrowingLoader";

const SelectField = ({
  name,
  options,
  onChange,
  label,
  touched,
  error,
  className,
  _label,
  _value,
  value,
  processing,
  disabled,
  loaderClassName,
}) => {
  const hasError = error && touched;

  const labelText = () => {
    if (label) return label;
    else {
      if (name) {
        let _label = unslufigy(name);
        if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
        return _label;
      }
    }
  };

  const Label = () => (
    <Div className={names(`label-text`)}>
      {labelText()} {hasError ? error + "." : null}
    </Div>
  );

  return (
    <Div className={names(`form-group`, hasError && `has-error`)}>
      <label>
        <Label />
      </label>

      <select
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled || processing}
        className={names(`form-control`, className)}
      >
        <option value="">-- Välj {labelText()} --</option>
        {options && options.length
          ? options.map((option) => {
              return (
                <option
                  key={option[_value || `id`]}
                  value={option[_value || `id`]}
                >
                  {ucFirst(option[_label || `name`])}
                </option>
              );
            })
          : null}
      </select>

      {processing && (
        <GrowingLoader className={names(`select-loader`, loaderClassName)} />
      )}
    </Div>
  );
};
export default memo(SelectField);
