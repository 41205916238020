export const settingType = [
  {name:"Public", value:"public"},
  {name:"Private", value:"private"}
];

export const hasRegion = [
  {name:"Yes", value:"1"},
  {name:"No", value:"0"}
];

export const approvalStatusData = [
  {name:"Pending", value:"pending"},
  {name:"Approved", value:"approved"},
  {name:"Rejected", value:"rejected"}
];

export const otpLength = 4;