import React from 'react';
import { names, ucFirst, unslufigy } from '../../../utils/StringUtils';
import Div from '../Div/Div';



function DateField( props )
{
  let {
    name, size, value, icon, onChange, id, noLabel, labelIcon, addonAfter, disabled, className, required,
    placeholder, addonBefore, label, error , touched
  } = props;

  const hasError = error && touched;
  
  const setPlaceholder = () =>
    {
        if ( placeholder ) return placeholder
        if ( label ) return label
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
                return _label;
            }
        }
    }
  
    const Label = () =>
    {
        if ( label ) return (
            <Div className={ names(`label-text`) }>
                { label } { hasError ? error + '.' : null }
            </Div>
        );
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
                return (
                    <Div className={ names(`label-text`) }>
                        { _label } { hasError ? error + '.' : null }
                    </Div>
                )
            }
        }
        return null
  }
  
  return (

      <div className={ names(`form-group`, error && error.length && `has-error`, required && `required`) }>

        { !noLabel ? <label><Label /></label> : null }

        <input type="date"
               className="form-control"
               size={size || `default`}
               name={name}
               disabled={disabled}
               id={id || name}
               value={ value }
               placeholder={ setPlaceholder() }
        onChange={onChange}
        
        />
      </div>

  )
}


export default DateField;