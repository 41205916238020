import { FolderState } from "./Folder-State";

export default function folderReducer(state = FolderState, action) {
  let { data } = { ...state };

  let { value, categories, name } = action;

  switch (action.type) {
    case "SET-FILTER-PAYLOAD":
      data.params[name] = value;
      return { ...state, data };

    case "SET-FOLDER-TOGGLE-MODAL-CLOSE":
      data.payload.folderModalOpen = false;
      data.payload.folderModalID = "";
      return { ...state, data };

    case "SET-FOLDER-TOGGLE-MODAL":
      data.payload.folderModalOpen = !data.payload.folderModalOpen;
      data.payload.folderModalID = value;
      return { ...state, data };

    case "SET-FOLDER-CATEGORIES":
      data.payload.categories = categories;
      return { ...state, data };

    default:
      return state;
  }
}
