import axios from 'axios'
import {apiBaseUrl} from "../../../utils/URLUtils";
import {loginToken} from "../../../utils/LocalStorageUtils";
import {handleError} from "../../../utils/ErrorUtils";
import {clone} from "../../../utils/DataUtils";

function _success(success) {
    return {type: 'GET_CATEGORIES_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_CATEGORIES_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_CATEGORIES_PROCESSING', processing};
}

function getCategories( limit )
{
    return (dispatch, getState) => {
        dispatch(_processing(true));
        let {categoryListReducer} = getState();

        let params = clone( categoryListReducer.data.params );
      if (limit) params.limit = limit;     

        axios({
            url:apiBaseUrl(`category`),
            method:'get',
            params,
            headers:{
                'Authorization': 'Bearer ' + loginToken()
            },
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });

    }
}

export default getCategories;