import React from 'react'

const Accordion = (props) => {
  const { title, id, children, collapseID, className } = props;
  return (
    <div className={`accordion-block ${className}`} id={id}>
      <a data-toggle="collapse" href={`#${collapseID}`} aria-expanded="false"
        aria-controls={collapseID} className="collapsed main-title" >
        <div className='heading'>
          <h5>{title} <span className="icon-up-arrow"></span></h5>
        </div>
      </a>
      <div id={collapseID} className="collapse main-content" data-parent={`#${id}`}>
        {children}
      </div>
    </div>
  )
}

export default Accordion