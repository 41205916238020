import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import getGroup, {
  getGroupReset,
} from "../../../../ajax/actions/group/group_get";
import { setDefaultGroupForm, setGroupDataForUpdate } from "./GroupForm-Action";
import { endOfURL, urlTrailWithoutLastItem } from "../../../../utils/URLUtils";
import { createGroupReset } from "../../../../ajax/actions/group/group_post";
import { updateGroupReset } from "../../../../ajax/actions/group/group_put";

const GroupFormWorker = () => {
  const reduxState = useSelector((state) => state);
  const { groupGet, groupUpdate, groupCreate } = reduxState;
  let dispatch = useDispatch();
  const history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    id && dispatch(getGroup(id));
    if (endOfURL() === "create") {
      dispatch(setDefaultGroupForm());
    }

    return () => {
      dispatch(getGroupReset());
    };
  }, []);

  useEffect(() => {
    if (groupGet.success) {
      dispatch(setGroupDataForUpdate());
    }
  }, [groupGet.success]);

  useEffect(() => {
    if (groupUpdate.success) {
      dispatch(updateGroupReset());
      history.push("/groups");
    }
  }, [groupUpdate.success]);

  useEffect(() => {
    if (groupCreate.success) {
      dispatch(createGroupReset());
      history.push(urlTrailWithoutLastItem());
    }
  }, [groupCreate.success]);

  return null;
};

export default GroupFormWorker;
