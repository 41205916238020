import axios from 'axios'
import {apiBaseUrl} from "../../../utils/URLUtils";
import {loginToken} from "../../../utils/LocalStorageUtils";
import {handleError} from "../../../utils/ErrorUtils";
import {clone} from "../../../utils/DataUtils";

function _success(success) {
    return {type: 'GET_USERS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_USERS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_USERS_PROCESSING', processing};
}

function getUsers( )
{
    return (dispatch, getState) =>
    {
        dispatch(_processing(true));
        let { userList } = getState();

        let _params = clone( userList.data.params );

        if( !_params.role_id ) delete _params.role_id;
        if( !_params.q ) delete _params.q;
        /*if( status === null ) {
            _params.status = 'approved';
        } else {
            _params.status = 'pending';
        }*/
        // if( !_params.status ) delete _params.status;
        axios({
            url:apiBaseUrl(`users`),
            method:'get',
            params: _params,
            headers:{
                'Authorization': 'Bearer ' + loginToken()
            }
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));

        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });
    }
}

export default getUsers;