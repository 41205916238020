import React, { memo } from 'react';
import newloginBg from "../../../../assets/images/ocean-oisf.png"

import blackBankID from "../../../../assets/images/bankid.svg"
import { useDispatch, useSelector} from "react-redux";

import TextField from "../../../shared/form/TextField";

import LoginFormWorker from "./../LoginFormWorker";
import SaveButton from "../../../shared/buttons/SaveButton";
import {Link} from "react-router-dom"
import { useFormik } from "formik";
import * as Yup from "yup";

import loginOTPUser from '../../../../ajax/actions/user/user_otp_login_post';
import MyIcon from '../../../shared/icons/MyIcon';


const MemberLoginForm = () =>
{
    const reduxState = useSelector(state => state);
    const { userOTPLoginReducer, } = reduxState;
    
    let dispatch = useDispatch();

    const { handleChange, handleSubmit, errors, touched, values, handleBlur } = useFormik(
        {
            initialValues: { email: ``,  },
            enableReinitialize: true,

            validationSchema: Yup.object().shape({
                email: Yup.string().required('is required').email(` must be a valid email address.`),
            }),

            onSubmit: values => {
                dispatch(loginOTPUser(values))
                
            }
        })    

        const background = {
            backgroundImage: `url(${newloginBg})`
        };

        return (

            <section className={`login-page`}>
                <div className="container-fluid p-0 h-100">
                    <div className="row h-100 align-items-center no-gutters">
                        <div className="col-lg-8 h-100 d-none d-lg-block">
                            <div className="logo-block h-100">
                                <div className="image-block h-100 w-100" style={background} />
                                <div className="content-block">
                                    <h1>OISF</h1>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="login-form">

                                <div className="login-logo d-block ">
                                    <h3>Logga in </h3>
                                    <h4>Medlemsportal</h4>
                                </div>

                                <p className="login-account">Logga in på ditt konto </p>
                                {/*<p className="account-sign-up">Don't have account?</p>*/}

                                <form onSubmit={handleSubmit}>

                                    <TextField name="email"
                                               value={values.email}
                                               error={errors && errors.email}
                                               label="E-post"
                                               placeholder="Ange din e-post..."
                                               touched={ touched.email }
                                               onChange={handleChange}/>
                                 <div className="text-center mt-30">
                                        
                                            <SaveButton text={`Logga in`}
                                                    type="submit"
                                                    btnClass="m-auto w-100" processing={userOTPLoginReducer.processing}/>
                                        
                                    </div>

                                </form>
                                <div className="text-center mt-30 bank-login-btn-wrapper">
                                    <Link to="/login-bank">
                                    <SaveButton text="Logga in med bankid"
                                            
                                            icon={blackBankID}
                                                    btnClass="m-auto btn-black w-100" />
                                    </Link>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <LoginFormWorker/>

            </section>

        );
}

export default memo( MemberLoginForm );