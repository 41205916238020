

export function setFilterPayload(name, value)
{
  return function (dispatch) {
    dispatch({type:'SET-FILTER-PAYLOAD', name, value});
  }
}
export function setFolderUpdateModal(value)
{
  return function (dispatch) {
    dispatch({type:'SET-FOLDER-TOGGLE-MODAL', value});
  }
}

export function setFolderModalClose()
{
  return function (dispatch) {
    dispatch({type:'SET-FOLDER-TOGGLE-MODAL-CLOSE'});
  }
}


export function setCategories(categories)
{
  return function (dispatch) {
    dispatch({type:'SET-FOLDER-CATEGORIES', categories});
  }
}

