import { CategoryListState } from './CategoryList-State'

export default function categoryListReducer(state = CategoryListState, action) {

  let { data } = { ...state }
  let { name, value, } = action;

  switch (action.type) {

    case 'TOGGLE-CATEGORY-LIST-FILTER':
      data.params[ name ] = value;
      if ( name === `sort_column` ) {
        if ( data.params.sort_column === value ) {
          data.params.sort_by = data.params.sort_by === `desc` ? `asc` : `desc`
        }
      }
      if ( name === `limit` ) {
        data.params.page = 1;
      }
      return { ...state, data };

    

    default: return state
  }
}
