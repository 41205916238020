import axios from "axios";
import { apiBaseUrl } from "../../../utils/URLUtils";
import { loginToken } from "../../../utils/LocalStorageUtils";
import { handleError, handleSuccess } from "../../../utils/ErrorUtils";
import store from "../../../store";

import getGroups from "./groups_get";

function _success(success) {
  return { type: "POST_GROUP_SUCCESS", success };
}
function _error(error) {
  return { type: "POST_GROUP_ERROR", error };
}
function _processing(processing) {
  return { type: "POST_GROUP_PROCESSING", processing };
}

function createGroup(values) {
  return (dispatch, getState) => {
    dispatch(_processing(true));

    axios({
      url: apiBaseUrl(`group`),
      method: "post",
      dataType: "json",
      data: values,
      headers: {
        Authorization: "Bearer " + loginToken(),
      },
    })
      .then(function (res) {
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess(res);
        store.dispatch(getGroups());
      })
      .catch(function (error) {
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError(error);
      });
  };
}
export function createGroupReset() {
  return (dispatch) => dispatch({ type: `POST_GROUP_RESET` });
}

export default createGroup;
