import React, { useState, useEffect } from "react";
import GroupWorker from "./GroupWorker";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import deleteGroup from "../../../../ajax/actions/group/group_delete";
import PaginationButtons from "../../../shared/buttons/PaginationButtons";
import GroupListFilters from "./GroupListFilters";
import { toggleGroupListFilter } from "./GroupList-Actions";
import Loading from "../../../shared/loaders/Loading";
import NoResultsFound from "../../../shared/folder/NoResultsFound";
import TableHead from "../../../shared/table/TableHead";
import Page from "../../../shared/pages/Page";
import { Link, useRouteMatch } from "react-router-dom";
import ActionCell from "../../../shared/table/ActionCell";

const Groups = () => {
  const [initialLoading, setInitialLoading] = useState(true);

  let dispatch = useDispatch();
  const reduxState = useSelector((state) => state);
  const { groupsGet, groupList, groupDelete } = reduxState;

  useEffect(() => {
    if (groupsGet.success && initialLoading) {
      setInitialLoading(false);
    }
  }, [groupsGet.success]);

  const deleteConfirm = (id) => {
    swal("Är du säker på att du vill radera denna roll? ", {
      buttons: {
        cancel: "Avbryt",
        catch: {
          text: "Radera",
          value: id,
        },
      },
    }).then((value) => {
      if (value) dispatch(deleteGroup(id));
    });
  };

  const toggleSort = (field) => {
    dispatch(
      toggleGroupListFilter({
        target: { name: `sort_by`, value: field },
      })
    );
  };

  let list = groupsGet.success && groupsGet.success.data.data;

  let current_page =
    groupsGet.success &&
    groupsGet.success.data &&
    groupsGet.success.data.current_page;
  let last_page =
    groupsGet.success &&
    groupsGet.success.data &&
    groupsGet.success.data.last_page;
  let total =
    groupsGet.success && groupsGet.success.data && groupsGet.success.data.total;
  let pages = { current_page, last_page, total };

  let { params, fields } = groupList.data;

  let { url } = useRouteMatch();

  return (
    <Page title="Grupp">
      <GroupWorker />
      <div className="page-title">
        <h2>Grupp {groupsGet.processing && <Loading marginLeft={20} />}</h2>
        <div className="button-block">
          <Link to={`/groups/create`}>
            <button type="button" className="btn-theme btn-gray">
              Skapa ny grupp
            </button>
          </Link>
        </div>
      </div>

      <GroupListFilters />

      <div className="table-dashboard table-small">
        <div className="table-responsive">
          <table className="table">
            <TableHead fields={fields} params={params} onClick={toggleSort} />

            <tbody>
              {list && list.length
                ? list.map((l, key) => {
                    return (
                      <tr key={key}>
                        <td className="clickable-col">{l.name}</td>
                        <td className="actions">
                          <ActionCell
                            id={l.id}
                            url={url}
                            role=""
                            onDelete={deleteConfirm}
                            deleteReducer={groupDelete}
                          />
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
            {list && list.length ? (
              <tfoot>
                <PaginationButtons
                  pages={pages}
                  processing={groupsGet.processing}
                  fetchAction={toggleGroupListFilter}
                />
              </tfoot>
            ) : null}
          </table>
          {!initialLoading && (!list || !list.length) && <NoResultsFound />}
          {initialLoading && (
            <div className="text-center">
              <Loading />
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default Groups;
