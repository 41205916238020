export function setDefaultGroupForm() {
  return function (dispatch) {
    dispatch({ type: "SET-DEFAULT-GROUP-FORM" });
  };
}

export function setGroupDataForUpdate() {
  return function (dispatch, getState) {
    let { groupGet } = getState();
    let groups = groupGet && groupGet.success && groupGet.success.data.message;
    if (groups) {
      dispatch({
        type: "SET-GROUP-DATA-FOR-UPDATE",
        groups: JSON.parse(JSON.stringify(groups)),
      });
    }
  };
}
