import React, { useState,  memo } from 'react';
import newloginBg from "../../../../assets/images/ocean-oisf.png"
import logoW from "../../../../assets/images/oisf.png";

import { useDispatch, useSelector} from "react-redux";

import LoginFormWorker from "../LoginFormWorker";
import SaveButton from "../../../shared/buttons/SaveButton";

import { useFormik } from "formik";
import "./style.scss"
import * as Yup from "yup";
import { loginEmail } from '../../../../utils/LocalStorageUtils';
import OtpField from '../../../shared/form/OtpField';
import { otpLength } from '../../../../static-data/SettingData';
import loginOTPValidate from '../../../../ajax/actions/user/user_otp_login_validate_post';
import loginOTPUser from '../../../../ajax/actions/user/user_otp_login_post';

const MemberOTPLoginForm = () =>
{  
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { userOTPLoginValidateReducer } = reduxState;    
    
  const [otp, setOtp] = useState(Array(otpLength).fill(''));
  const [fallBack, setFallback] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);

    const { handleSubmit } = useFormik(
    {
        initialValues: { email: loginEmail(), otp },
        enableReinitialize: true,

        validationSchema: Yup.object().shape({
            otp: Yup.string().required('krävs'),
        }),

        onSubmit: values => {
            const finalValue = otp.reduce((acc, item) => acc + item, ''); // Join into a single string
            const numericValue = Number(finalValue);
            values.otp = numericValue;
            dispatch(loginOTPValidate(values))           
        }
      }) 
  
  React.useEffect(() => {

    const timerInterval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev > 0) return prev - 1;
        clearInterval(timerInterval); // Clear interval when timer hits 0
        return 0;
      });
    }, 1000); 

    // Start a 30-second timeout for the fallback
    const timer = setTimeout(() => {
      setFallback(true); // Trigger fallback after 30 seconds
    },60000 );

    return () => clearTimeout(timer); // Clean up timeout on unmount
  }, [userOTPLoginValidateReducer.success]);

    const background = {
        backgroundImage: `url(${newloginBg})`
    };    

    return (
        <section className={`login-page`}>
            <div className="container-fluid p-0 h-100">
                <div className="row h-100 align-items-center no-gutters">
                    <div className="col-lg-8 h-100 d-none d-lg-block">
                        <div className="logo-block h-100">
                            <div className="image-block h-100 w-100" style={background} />
                            <div className="content-block">
                                <h1>OISF</h1>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="login-form">

                            <div className="login-logo d-block ">
                                <h3>Logga in </h3>
                                <h4>Medlemsportal</h4>
                            </div>

                            <p className="account-sign-up text-center">Skriv in din kod</p>

                            
                            <form onSubmit={handleSubmit}>
                                <div className="div-otp-wrapper">
                                    <OtpField length={4} otp={otp} setOtp={setOtp} />
                                </div>                                
                                <div className='otp-no-message'>
                                  <span>OTP vreification code not received 
                                  {fallBack ? <label style={{color:'blue'}}
                                    onClick={() => dispatch(loginOTPUser({ email: loginEmail() }))}
                                  >Send Again </label>: <span> {  timeLeft } sec</span>}
                                  </span>
                                  
                                </div>

                                <div className="text-center mt-30">
                                    <SaveButton
                                        text="Logga in"
                                        type="submit"
                                        btnClass="m-auto w-100"
                                        disabled={otp.some(item => item === '')}
                                        processing={userOTPLoginValidateReducer.processing} />
                                    </div>
                            </form>
                
                        </div>
                    </div>
                </div>
            </div>
            <LoginFormWorker/>
        </section>
    );
}

export default memo( MemberOTPLoginForm );