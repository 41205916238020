const DefaultGroupListState = {
  payload: {
    initialLoading: true,
    flashMessage: null,
    error: "",
    success: "",
  },
  params: {
    limit: 25,
    page: 1,
    sort_column: "id",
    sort_by: `desc`,
    q: ``,
  },
  modal: {
    mode: ``,
    id: ``,
  },
  fields: [{ field: `name`, label: `NAME`, sort: true }],
};

export const GroupListState = { data: DefaultGroupListState };
