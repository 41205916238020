import { LoginFormState } from './LoginForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from "../../../utils/ErrorUtils";
import {isObject} from "../../../utils/DataUtils";


export default function loginFormReducer (state = LoginFormState, action) {
  let {data} = {...state};
  let { validationErrors, name, value, page } = action;

  switch (action.type) {

    case `SET-REDIRECT-TO-PAGE-AFTER-LOGIN`:
      if ( page ) {
        data.redirectTo = page
      }
      return {...state, data}

    case 'ON-LOGIN-FORM-CHANGE':
      data.payload[name] = value;
      buildErrors()
      return { ...state, data }
    
     

    case 'CANCEL-LOGIN-FORM-DISPATCH':
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case 'ON-LOGIN-FORM-SUBMIT':
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case 'LOGIN-FORM-SERVER-VALIDATION-ERRORS':
      validationErrors && isObject( validationErrors ) && Object.keys(validationErrors).map(key =>
          data.errors[key] = [{ message: ` - ${validationErrors[key][0]}`.slice(0, -1) }]
      );
      data.dispatchAPI = false;
      return { ...state, data };

    default: return state;
  }

  function buildErrors() {
    data.errors = errorsList(errors);
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field) {
    let { payload, submitted, validationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted) {
      if (field === `email`) {
        if (!_(field)) newError(field, 0, `is required`)
      }

      if (field === `password`) {
        if (!_(field)) newError(field, 0, `is required`)
      }
    }

    errors = mergeServerValidationErrors(errors, validationErrors);

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}