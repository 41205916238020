import axios from "axios";
import { apiBaseUrl } from "../../../../utils/URLUtils";
import { loginToken } from "../../../../utils/LocalStorageUtils";
import { handleError, handleSuccess } from "../../../../utils/ErrorUtils";
import store from "../../../../store";
import getFolders from "./folders_get";

function _success(success) {
  return { type: "CREATE_FOLDER_SUCCESS", success };
}

function _error(error) {
  return { type: "CREATE_FOLDER_ERROR", error };
}

function _processing(processing) {
  return { type: "CREATE_FOLDER_PROCESSING", processing };
}

function createFolder(values) {
  return (dispatch) => {
    dispatch(_processing(true));
    let formData = new FormData();

    // formData.append(`files`, values.files);
    (values.files || []).forEach((file, index) => {
      formData.append(`file[${index}]`, file); // Append the file
    });

    formData.append(`category_id`, values.category_id);
    formData.append(`name`, values.name);
    formData.append(`description`, values.description);
    formData.append(`parent_id`, values.parent_id);
    (values.group_ids || []).forEach((file, index) => {
      formData.append(`group_ids[${index}]`, file); // Append the file
    });

    axios({
      url: apiBaseUrl(`folder`),
      method: "post",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + loginToken(),
      },
    })
      .then(function (res) {
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess(res);
        store.dispatch(getFolders());
      })
      .catch(function (error) {
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError(error);
      });
  };
}
export function createFolderReset() {
  return (dispatch) => dispatch({ type: `CREATE_FOLDER_RESET` });
}
export default createFolder;
