import React from 'react';
import Loading from "../loaders/Loading";

const SaveButton = (props) =>
{
  let { processing, text, btnClass, style, icon, type, disabled, processingHeight, processingWidth } = props;    
  return (
    <button className={`btn-theme btn-blue ${btnClass}`} type={ type || 'button' } disabled={(processing || disabled) ? true : null} style={ style }>
        {processing ? <Loading color="white" width={processingWidth} height={processingHeight} /> : text || 'SPARA'}
        {icon ? <img src={icon} alt="img"  /> : null}
    </button>
  )
}
export default SaveButton;