import React from "react";
import { useDispatch, useSelector } from "react-redux";
import getFolders from "../../../../ajax/actions/document/folder/folders_get";
import { setFilterPayload } from "./Folder-Actions";
import useDefaultArea from "../../../hooks/useDefaultArea";
import getDocuments from "../../../../ajax/actions/document/documents_get";

const Filter = () => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);
  const { folderReducer } = reduxState;

  const [categoryID, setCategoryID] = React.useState([]);

  // const { q, category_id } = folderReducer.data.params;
  const { categories } = folderReducer.data.payload;

  const area = useDefaultArea();

  const onFilter = (id) => {
    if (categoryID.includes(id)) {
      setCategoryID((prev) => prev.filter((category) => category !== id));
    } else {
      setCategoryID((prev) => [...prev, id]);
    }
  };

  React.useEffect(() => {
    if (categoryID.length) {
      let filterParams = {
        category_id: categoryID,
      };
      area !== "member"
        ? dispatch(getFolders(filterParams))
        : dispatch(getDocuments(filterParams));
    }
  }, [categoryID]);

  const onFilterReset = () => {
    setCategoryID([]);
    area !== "member" ? dispatch(getFolders()) : dispatch(getDocuments());
  };

  return (
    <div className="filter-block-wrapper">
      <ul className="filter-block">
        <li
          className={!categoryID.length ? "active" : ""}
          onClick={() => onFilterReset()}
        >
          Alla kategorier
        </li>
        {categories.length
          ? categories.map((c, key) => {
              return (
                <li
                  className={categoryID.includes(c.id) ? "active" : ""}
                  key={key}
                  onClick={() => onFilter(c.id)}
                >
                  {c.name}
                </li>
              );
            })
          : null}
      </ul>

      <div className="button-wrapper">
        <button onClick={() => onFilterReset()}>Rensa &#10006;</button>
      </div>
    </div>
  );
};

export default Filter;
