import axios from 'axios'
import {apiBaseUrl} from "../../../../utils/URLUtils";
import {loginToken} from "../../../../utils/LocalStorageUtils";
import {handleError, handleSuccess} from "../../../../utils/ErrorUtils";


function _success(success) {
    return {type: 'DELETE_FILE_SUCCESS', success};
}

function _error(error) {
    return {type: 'DELETE_FILE_ERROR', error};
}

function _processing(processing) {
    return {type: 'DELETE_FILE_PROCESSING', processing};
}

function deleteFile( id )
{
    return (dispatch) => {
        dispatch(_processing(true));
        
        axios({
            url:apiBaseUrl(`files/${id}`),
            method:'delete',
            headers:{
                'Authorization': 'Bearer ' + loginToken()
            },
        }).then(function(res){
            dispatch(_success(res));
          dispatch(_processing(false));
          handleSuccess(res);
          
        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });

    }
}

export default deleteFile;