import React, { memo } from "react";
import { names, ucFirst, unslufigy } from "../../../utils/StringUtils";
import Div from "../Div/Div";
import GrowingLoader from "../loaders/GrowingLoader";
import Select from "react-select";

const MultiSelectField = ({
  name,
  options,
  onChange,
  label,
  touched,
  error,
  className,
  _label,
  _value,
  value,
  processing,
  disabled,
  loaderClassName,
  placeholder,
}) => {
  const hasError = error && touched;

  const labelText = () => {
    if (label) return label;
    else {
      if (name) {
        let _label = unslufigy(name);
        if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
        return _label;
      }
    }
  };

  const Label = () => (
    <Div className={names(`label-text`)}>
      {labelText()} {hasError ? error + "." : null}
    </Div>
  );

  return (
    <Div className={names(`form-group`, hasError && `has-error`)}>
      <label>
        <Label />
      </label>

      {/*<select name={ name }
                    value={ value }
                    onChange={ onChange }
                    disabled={ disabled || processing }
                    className={ names( `form-control`, className ) } >

              <option value="">-- Select { labelText() } --</option>
              { options && options.length ? options.map( option => {
                return (

                    <option key={ option[ _value || `id` ] }
                            value={ option[ _value || `id` ] }>{ option[ _label || `name` ] }</option>
                )
              }) : null }
            </select>*/}
      <Select
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        isMulti
      />

      {processing && (
        <GrowingLoader className={names(`select-loader`, loaderClassName)} />
      )}
    </Div>
  );
};
export default memo(MultiSelectField);
