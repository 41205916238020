
const DefaultLoginState = {
  payload:{
    // email: ``,
    username: ``,
    password: ``,
  },
  
  redirectTo: ``,
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  message: ``

};

export const LoginFormState = {data: DefaultLoginState};

