export default function putFolderReducer
  (state = { error:'', success:'', processing:false}, action)
{
  switch (action.type) {
    case 'UPDATE_FOLDER_SUCCESS':
      return {...state, ...{success: action.success}};

    case 'UPDATE_FOLDER_ERROR':
      return {...state, ...{error: action.error}};

    case 'UPDATE_FOLDER_PROCESSING':
      return {...state, ...{processing: action.processing}};

    case 'UPDATE_FOLDER_RESET':
      return { ...state, ...{
          success: null,
          error: null,
          processing:false
        }
      };
    default: return state;
  }
}