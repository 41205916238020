import React from "react";
import { useDispatch, useSelector } from "react-redux";
import GroupFormWorker from "./GroupFormWorker";
import SaveButton from "../../../shared/buttons/SaveButton";
import TextField from "../../../shared/form/TextField";
import { useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import updateGroup from "../../../../ajax/actions/group/group_put";
import createGroup from "../../../../ajax/actions/group/group_post";
import { apiBaseUrl, endOfURL } from "../../../../utils/URLUtils";
import getUsers from "../../../../ajax/actions/user/users_get";
import AsyncSelect from "react-select/async";
import { loginToken } from "../../../../utils/LocalStorageUtils";
import Axios from "axios";
import clsx from "clsx";
import Div from "../../../common/Div/Div";

const GroupForm = () => {
  const reduxState = useSelector((state) => state);
  const [users, setUsers] = React.useState([]);
  const [userLimit, setUserLimit] = React.useState(25);
  const [loading, setLoading] = React.useState(false); // <-- Track loading state

  const [selectedUser, setSelectedUser] = React.useState([]);

  const { groupForm, groupCreate, groupUpdate, groupGet, usersGet } =
    reduxState;

  let dispatch = useDispatch();

  let { id } = useParams();

  let fields = { ...groupForm.payload };

  const { handleChange, handleSubmit, errors, touched, values, setFieldValue } =
    useFormik({
      initialValues: fields,
      enableReinitialize: true,

      validationSchema: Yup.object().shape({
        name: Yup.string().required(` krävs`),
        user_ids: Yup.array()
          .of(Yup.string())
          .min(1, `Minst en användare krävs`)
          .required(`krävs`),
      }),

      onSubmit: (values) => {
        if (id) {
          dispatch(updateGroup(values, id));
        } else {
          dispatch(createGroup(values));
        }
      },
    });

  React.useEffect(() => {
    setSelectedUser([]);
    dispatch(getUsers());
  }, []);

  React.useEffect(() => {
    if (usersGet.processing) setSelectedUser([]);
  }, [usersGet]);

  React.useEffect(() => {
    if (usersGet.success) {
      const users = usersGet.success.data.data;
      const options = users.reduce((acc, ite) => {
        return [
          ...acc,
          { label: `${ite.first_name} ${" "} ${ite.last_name}`, value: ite.id },
        ];
      }, []);
      setUsers(options);
      const selectedUserOptions = values?.defaultSelectedUsers?.reduce(
        (acc, ite) => {
          if (fields.user_ids.includes(ite.id)) {
            return [
              ...acc,
              {
                label: `${ite.first_name} ${" "} ${ite.last_name}`,
                value: ite.id,
              },
            ];
          }
          return acc;
        },
        []
      );
      setSelectedUser(selectedUserOptions);
    }
  }, [usersGet.success]);

  const handleUserChange = (val) => {
    setSelectedUser(val);
    const values = [];
    val &&
      val.map((ite) => {
        values.push(ite.value);
      });

    setFieldValue("user_ids", values);
  };

  let processing = groupCreate.processing || groupUpdate.processing;

  const fetchUsers = async (page, limit, inputValue) => {
    const updatedLimit = !inputValue ? limit + 25 : limit;
    setUserLimit(updatedLimit);

    try {
      const response = await Axios.get(apiBaseUrl("users"), {
        params: {
          page,
          limit: updatedLimit,
          q: inputValue,
          status: "approved",
        },
        headers: { Authorization: `Bearer ${loginToken()}` },
      });

      return response.data?.data || [];
    } catch (error) {
      console.error("Error fetching users:", error);
      return [];
    }
  };

  const loadUserOptions = async (inputValue) => {
    try {
      setLoading(true);
      const users = await fetchUsers(1, userLimit, inputValue);
      const newOptions = users.map((user) => ({
        label: `${user.first_name} ${" "} ${user.last_name}`,
        value: user.id,
      }));
      setUsers((prevUsers) => [...prevUsers, ...newOptions]); // Append new users

      return newOptions;
    } catch (error) {
      console.error("Error loading user options:", error);
      return [];
    } finally {
      setLoading(false); // <-- Set loading to false
    }
  };

  return (
    <div className={groupGet.processing ? `item-disabled` : null}>
      <div className="offset-lg-1 col-lg-7 col-md-12">
        <h2>
          {endOfURL() === "create"
            ? "Skapa ny grupp"
            : endOfURL() === "update"
            ? "Uppdatera grupp"
            : ""}
        </h2>
        <form onSubmit={handleSubmit}>
          <TextField
            name="name"
            placeholder="Namn"
            value={values.name}
            error={errors && errors.name}
            touched={touched.name}
            onChange={handleChange}
            label="Name"
          />

          <Div
            className={clsx("form-group async-select ", {
              "has-error": errors.user_ids && touched.user_ids,
            })}
          >
            {errors.user_ids && touched.user_ids ? (
              <Div className={`label-text`}>User {errors.user_ids + "."}</Div>
            ) : null}
            <AsyncSelect
              className="select-field"
              name="user_ids"
              placeholder="Välj användare..."
              defaultOptions={users}
              value={selectedUser}
              isLoading={loading}
              error={errors && errors.user_ids}
              touched={touched.user_ids}
              onChange={handleUserChange}
              loadOptions={loadUserOptions}
              onMenuScrollToBottom={() => loadUserOptions("", () => {})}
              cacheOptions
              isMulti
            />
          </Div>

          <div className="text-center">
            <SaveButton type="submit" processing={processing} />
          </div>

          <GroupFormWorker />
        </form>
      </div>
    </div>
  );
};

export default GroupForm;
