import { GroupListState } from "./GroupList-State";

export default function groupListReducer(state = GroupListState, action) {
  let { data } = { ...state };
  let { name, value, id } = action;

  switch (action.type) {
    case "TOGGLE-GROUP-LIST-FILTER":
      data.params[name] = value;
      if (name === `sort_column`) {
        if (data.params.sort_column === value) {
          data.params.sort_by = data.params.sort_by === `desc` ? `asc` : `desc`;
        }
      }
      if (name === `limit`) {
        data.params.page = 1;
      }
      return { ...state, data };

    case "TOGGLE-GROUP-FORM-MODAL":
      if (data.modal.mode) {
        // if open, close
        data.modal = { mode: ``, id: `` };
      } else {
        // if close, open
        data.modal.id = id || ``;
        data.modal.mode = id ? `update` : `create`;
      }
      return { ...state, data };

    default:
      return state;
  }
}
