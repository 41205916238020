import React from "react";
import { useDispatch, useSelector } from "react-redux";
import RoleFormWorker from "./RoleFormWorker";
import SaveButton from "../../../shared/buttons/SaveButton";
import TextField from "../../../shared/form/TextField";
import { slugify, ucFirst } from "../../../../utils/StringUtils";
import { useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import updateRole from "../../../../ajax/actions/role/role_put";
import createRole from "../../../../ajax/actions/role/role_post";
import { endOfURL } from "../../../../utils/URLUtils";

const RoleForm = () => {
  const reduxState = useSelector((state) => state);
  const { roleForm, roleCreate, roleUpdate, roleGet } = reduxState;
  let dispatch = useDispatch();

  let { id } = useParams();

  let fields = { ...roleForm.payload };
  const {
    handleChange,
    handleSubmit,
    errors,
    touched,
    values,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: fields,
    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      title: Yup.string().required(` krävs`),
      slug: Yup.string().required(` krävs`),
    }),

    onSubmit: (values) => {
      if (id) {
        dispatch(updateRole(values, id));
      } else {
        dispatch(createRole(values));
      }
    },
  });

  const onChangeTitle = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "title") {
      if (value) {
        setFieldValue("slug", slugify(value));
      } else {
        resetForm({ title: "", slug: "" });
      }
    }

    setFieldValue(name, value);
  };

  let processing = roleCreate.processing || roleUpdate.processing;
  return (
    <div className={roleGet.processing ? `item-disabled` : null}>
      <div className="offset-lg-1 col-lg-7 col-md-12">
        <h2>
          {endOfURL() === "create"
            ? "Skapa ny roll"
            : endOfURL() === "update"
            ? "Uppdatera roll"
            : ""}
        </h2>
        <form onSubmit={handleSubmit}>
          <TextField
            name="title"
            value={values.title}
            error={errors && errors.title}
            touched={touched.title}
            onChange={(e) => onChangeTitle(e)}
            label="Titel"
          />

          <TextField
            name="slug"
            value={values.slug}
            error={errors && errors.slug}
            touched={touched.slug}
            onChange={handleChange}
          />

          <div className="text-center">
            <SaveButton type="submit" processing={processing} />
          </div>

          <RoleFormWorker />
        </form>
      </div>
    </div>
  );
};

export default RoleForm;
