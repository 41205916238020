import axios from 'axios'
import {apiBaseUrl} from "../../../../utils/URLUtils";
import {loginToken} from "../../../../utils/LocalStorageUtils";
import {handleError, handleSuccess} from "../../../../utils/ErrorUtils";


function _success(success) {
    return {type: 'UPLOAD_FILES_SUCCESS', success};
}

function _error(error) {
    return {type: 'UPLOAD_FILES_ERROR', error};
}

function _processing(processing) {
    return {type: 'UPLOAD_FILES_PROCESSING', processing};
}

function uploadFiles( values )
{
    return (dispatch) => {
        dispatch(_processing(true));
      let formData = new FormData();
      
      values.files.forEach((file, index) => {      
        formData.append(`file[${index}]`, file); // Append the file
      });
      
        axios({
          url:apiBaseUrl(`files/${values.folder_id}`),
          method: 'post',
          data: formData,
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization': 'Bearer ' + loginToken()
          },
        }).then(function(res){
            dispatch(_success(res));
          dispatch(_processing(false));
          handleSuccess(res);
          
        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });

    }
}

export default uploadFiles;