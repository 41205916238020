export const AccountFormState = {
  payload: {
    first_name: ``,
    last_name: ``,
    username: ``,
    email: ``,
    address: ``,
    post_address: ``,
    // company_name:``,
    // second_address_line:``,
    // postcode:``,
    // city:``,
    // state:``,
    // country:``,
    personal_number: ``,
    grad: ``,
    forb: ``,
    offk: ``,
    stand: ``,
    samtycke: false,
    password: ``,
    password_confirmation: ``,
    role_id: ``,
    note: ``,
    // member:``,
  },
};
