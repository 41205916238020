import React from "react";
import DocumentFile from "./DocumentFile";
import deleteFile from "../../../ajax/actions/document/file/file_delete";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { setFolderUpdateModal } from "./folder/Folder-Actions";
import "./style.scss";
import ActionPopover from "../../common/ActionPopover/ActionPopover";
import { Popover } from "react-bootstrap";
import AddFiles from "./folder/AddFiles";
import useDefaultArea from "../../hooks/useDefaultArea";
import logoW from "../../../assets/images/more-option-icon.svg";
import clsx from "clsx";

const DocumentCard = ({
  document,
  count,
  hasAction,
  deleteAction,
  isChild = false,
}) => {
  const dispatch = useDispatch();

  const { name, description, files, headline, id, group_ids } = document;

  const [documentFiles, setDocumentFiles] = React.useState(files);
  const [deleteFileID, setDeleteFileID] = React.useState("");

  const reduxState = useSelector((state) => state);
  const { deleteFileReducer, folderReducer, uploadFilesReducer } = reduxState;

  const onDeleteFile = (id) => {
    swal("Är du säker på att du vill radera denna file? ", {
      buttons: {
        cancel: "Avbryt",
        catch: {
          text: "Radera",
          value: id,
        },
      },
    }).then((value) => {
      if (value) {
        setDeleteFileID(id);
        dispatch(deleteFile(id));
      }
    });
  };

  React.useEffect(() => {
    if (uploadFilesReducer.success) {
      const data = uploadFilesReducer.success.data.data;
      if (data.id === id) {
        setDocumentFiles(data.files);
      }
    }
  }, [uploadFilesReducer.success]);

  React.useEffect(() => {
    if (deleteFileReducer.success) {
      setDocumentFiles((prev) =>
        prev.filter((item) => item.id !== deleteFileID)
      );
      setDeleteFileID("");
    }
  }, [deleteFileReducer.success]);

  const area = useDefaultArea();

  return (
    <div className={clsx("card", { "card-child": isChild })}>
      <div
        className={`card-header ${
          group_ids !== null && "null" ? "has-role" : null
        }`}
      >
        <a>
          <div className="title-wrapper">
            <div className="title-block">
              <div className="sub-title">{headline}</div>
              <div className="main-title">{name}</div>
            </div>
            <div className="action-block">
              {hasAction && (
                <div className="has-actions">
                  <ActionPopover
                    popover={
                      <Popover id={`action-popover-${id}`}>
                        <Popover.Body>
                          {" "}
                          <span
                            type="button"
                            onClick={() => dispatch(setFolderUpdateModal(id))}
                          >
                            Redigera
                          </span>
                        </Popover.Body>
                        <Popover.Body>
                          <span
                            className="delete-btn-custom"
                            type="button"
                            onClick={() => deleteAction(id)}
                          >
                            Radera Mapp
                          </span>
                        </Popover.Body>
                      </Popover>
                    }
                    // label={<div className='popover-toggle-btn'><span><img src={logoW} /></span></div>}
                    className="test"
                    flagShow={folderReducer.data.payload.folderModalOpen} //if modal is open then the popover should hide
                  />
                </div>
              )}
              <div
                className="icons-block collapsed"
                data-toggle="collapse"
                href={`#collapse-${id}`}
                aria-expanded={count === 0 ? true : false}
                aria-controls={`collapse-${id}`}
              >
                <div className="icons-wrapper">
                  <span className="icon-long-arrow"></span>
                </div>
                <div className="icons-text-wrapper">
                  <span className="span-text">Stäng</span>{" "}
                  <span className="icon-up-arrow"></span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div
        id={`collapse-${id}`}
        className={`collapse ${count === 0 ? "show" : ""}`}
        // data-parent="#accordion1"
        data-parent={isChild ? null : "#accordion1"}
      >
        <div className="card-body">
          <div className="card-body-content">
            {/* subchild of the folder */}
            {document?.children?.length
              ? document.children.map((subChild, index) => {
                  return (
                    <DocumentCard
                      document={subChild}
                      key={index}
                      // count={index}
                      isChild={true}
                      hasAction={!!hasAction}
                      deleteAction={deleteAction}
                    />
                  );
                })
              : null}
            <div className="text-content">
              <p>{description}</p>
              {/* <h3 className="text-uppercase">Beskrivning</h3>
                  <p>Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
            </div>
            <div className="file-list-block pt-25">
              {documentFiles && documentFiles.length
                ? documentFiles.map((item, key) => (
                    <DocumentFile
                      key={key}
                      file={item}
                      deleteFileAction={onDeleteFile}
                      folderID={id}
                    />
                  ))
                : null}
              {area !== "member" && <AddFiles folderID={id} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentCard;
