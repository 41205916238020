import React from 'react'
import UploadImageNew from '../../../common/form/UploadImageNew';
import { useDispatch, useSelector } from 'react-redux';
import uploadFiles from '../../../../ajax/actions/document/file/files_upload';
import getFolders from '../../../../ajax/actions/document/folder/folders_get';

const AddFiles = ({folderID}) => {
  const [ uploadKey, setUploadKey ] = React.useState(Math.random().toString(36));
  const [payload, setPayload] = React.useState({
    folder_id:folderID,
    files: [],
  });

  const dispatch = useDispatch();

  const reduxState = useSelector(state => state);
  const { uploadFilesReducer } = reduxState;
  
  
  const onHandleChange = (e) => {
    const { name, files } = e.target;
    
    if (name === "files" && files && files.length > 0) {
      
      const fileArray = Array.from(files).map((file) => {
      
      Object.defineProperty(file, 'id', {
        value: `${Date.now()}-${Math.random()}`, // Generate a unique identifier
        
        });
        return file;
      });
      // Update the payload state
      setPayload((prev) => ({
        ...prev,
        files: [...prev.files, ...fileArray],
      }));
    } 
  };

  React.useEffect(() => {
    if (payload.files.length > 0) {
      dispatch(uploadFiles(payload));
    }
  }, [payload.files.length])
  

  const resetForm = () => {
    setPayload({
        folder_id: folderID,
        files: [],
      })
  }  

  React.useEffect(() => {
    if (uploadFilesReducer.success) {
      resetForm();
      // dispatch(getFolders())
    }
  }, [uploadFilesReducer.success])
  
  const processing = React.useMemo(() => {
    return uploadFilesReducer.processing;
  }, [uploadFilesReducer])
  
  return (
    <div className='folder-block'> 
      <div className='row'>
        <div className='col-md-12'>
          <UploadImageNew
            keys={uploadKey}
            multiple
            processing={processing}
            name="files" value={payload.files} onChange={(e) => onHandleChange(e)} />
        </div>
      </div>
    </div>
  )
}

export default AddFiles