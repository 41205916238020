import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

import PaginationButtons from "../../../shared/buttons/PaginationButtons";
import RoleListFilters from "./CategoryListFilters";
import { toggleCategoryListFilter } from "./CategoryList-Actions";
import Loading from "../../../shared/loaders/Loading";
import NoResultsFound from "../../../shared/folder/NoResultsFound";
import TableHead from "../../../shared/table/TableHead";
import Page from "../../../shared/pages/Page";
import { Link, useRouteMatch } from "react-router-dom";
import ActionCell from "../../../shared/table/ActionCell";
import deleteCategory from "../../../../ajax/actions/category/category_delete";
import getCategories from "../../../../ajax/actions/category/categories_get";

const Category = () => {
  const [initialLoading, setInitialLoading] = useState(true);

  let dispatch = useDispatch();
  const reduxState = useSelector((state) => state);
  const { getCategoriesReducer, categoryListReducer, deleteCategoryReducer } =
    reduxState;

  React.useEffect(() => {
    dispatch(getCategories(10));
  }, []);

  useEffect(() => {
    if (getCategoriesReducer.success && initialLoading) {
      setInitialLoading(false);
    }
  }, [getCategoriesReducer.success]);

  const deleteConfirm = (id) => {
    swal("Är du säker på att du vill radera denna category? ", {
      buttons: {
        cancel: "Avbryt",
        catch: {
          text: "Radera",
          value: id,
        },
      },
    }).then((value) => {
      if (value) dispatch(deleteCategory(id));
    });
  };

  const toggleSort = (field) => {
    dispatch(
      toggleCategoryListFilter({
        target: { name: `sort_by`, value: field },
      })
    );
  };

  let list =
    getCategoriesReducer.success && getCategoriesReducer.success.data.data;

  let current_page =
    getCategoriesReducer.success &&
    getCategoriesReducer.success.data &&
    getCategoriesReducer.success.data.current_page;
  let last_page =
    getCategoriesReducer.success &&
    getCategoriesReducer.success.data &&
    getCategoriesReducer.success.data.last_page;
  let total =
    getCategoriesReducer.success &&
    getCategoriesReducer.success.data &&
    getCategoriesReducer.success.data.total;
  let pages = { current_page, last_page, total };

  let { params, fields } = categoryListReducer.data;
  let { url } = useRouteMatch();

  return (
    <Page title="Kategorier">
      <div className="page-title">
        <h2>
          Kategorier{" "}
          {getCategoriesReducer.processing && <Loading marginLeft={20} />}
        </h2>
        <div className="button-block">
          <Link to={`/category/create`}>
            <button type="button" className="btn-theme btn-gray">
              Skapa ny kategorier
            </button>
          </Link>
        </div>
      </div>

      <RoleListFilters />

      <div className="table-dashboard table-small">
        <div className="table-responsive">
          <table className="table">
            <TableHead fields={fields} params={params} onClick={toggleSort} />

            <tbody>
              {list && list.length
                ? list.map((l, key) => {
                    return (
                      <tr key={key}>
                        <td>{l.id}</td>
                        <td className="clickable-col">{l.name}</td>

                        <td className="actions">
                          <ActionCell
                            id={l.id}
                            url={url}
                            role=""
                            onDelete={deleteConfirm}
                            deleteReducer={deleteCategoryReducer}
                          />
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
            {list && list.length ? (
              <tfoot>
                <PaginationButtons
                  pages={pages}
                  processing={getCategoriesReducer.processing}
                  fetchAction={toggleCategoryListFilter}
                />
              </tfoot>
            ) : null}
          </table>
          {!initialLoading && (!list || !list.length) && <NoResultsFound />}
          {initialLoading && (
            <div className="text-center">
              <Loading />
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default Category;
