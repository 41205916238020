import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { loginToken } from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import {clone} from "../../../utils/DataUtils";
import store from "../../../store";
/*import {
    cancelUserFormDispatch,
    userFormServerValidationErrors
} from "../../../components/pages/users/form/UserForm-Action";*/
// import {toggleUserFormModal} from "../../../components/pages/users/list/UserList-Actions";
import getUsers from "./users_get";

function _success(success)
{
    return { type: 'POST_USER_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_USER_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_USER_PROCESSING', processing };
}

function createUser( values )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        if ( !values.member ) delete values.member;
        if ( !values.offk ) delete values.offk;
        axios({
            url: apiBaseUrl(`register`),
            method: "post",
            dataType: 'json',
            data: values,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function(res){
                dispatch(_success(res));
                handleSuccess( res );
                store.dispatch(getUsers());

            }).catch(function(error)
            {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error );
                /*if ( error.response && error.response.status === 422 ) {
                    store.dispatch(cancelUserFormDispatch());
                    store.dispatch( userFormServerValidationErrors( error.response.data.payload.errors ))
                }*/
            });
    }
}
export function createUserReset()
{
    return dispatch => dispatch({ type: `POST_USER_RESET` })
}

export default createUser;