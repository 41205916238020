import { combineReducers } from "redux";

import app from "./components/layout/App/App-Reducers";
import routes from "./components/modules/route/route-reducers";
import loginForm from "./components/modules/login/LoginForm-Reducer";
import main from "./components/layout/Main/Main-Reducers";

import userLogin from "./ajax/reducers/user/user-login-post";
import userLogOut from "./ajax/reducers/user/user-logout-post";
import detailsUser from "./ajax/reducers/user/user-detail-get";

import userUpdate from "./ajax/reducers/user/user-put";
import userCreate from "./ajax/reducers/user/user-post";
import userApprove from "./ajax/reducers/user/user-approve-post";
import usersGet from "./ajax/reducers/user/users-get";
import allUsersGet from "./ajax/reducers/user/users-all-get";
import userGet from "./ajax/reducers/user/user-get";
import userStatsGet from "./ajax/reducers/user/user-stats-get";
import userDelete from "./ajax/reducers/user/user-delete";
import myAccountUpdate from "./ajax/reducers/user/my-account-put";
import accountForm from "./components/modules/account/AccountForm-Reducer";
import userList from "./components/modules/users/list/UserList-Reducer";
import userForm from "./components/modules/users/form/UserForm-Reducer";
import updateBulkUsers from "./ajax/reducers/user/bulk-users-import";
import bulkUserUpdateForm from "./components/modules/users/bulk-update-form/BulkUserUpdateForm-Reducer";
// import passwordForm from "./components/modules/account/password/PasswordForm-Reducer";

import rolesGet from "./ajax/reducers/role/roles-get";
import roleGet from "./ajax/reducers/role/role-get";
import roleCreate from "./ajax/reducers/role/role-post";
import roleUpdate from "./ajax/reducers/role/role-put";
import roleDelete from "./ajax/reducers/role/role-delete";
import roleList from "./components/modules/roles/list/RoleList-Reducer";
import roleForm from "./components/modules/roles/form/RoleForm-Reducer";

import allGroupsGet from "./ajax/reducers/group/groups-all-get";
import groupsGet from "./ajax/reducers/group/groups-get";
import groupGet from "./ajax/reducers/group/group-get";
import groupCreate from "./ajax/reducers/group/group-post";
import groupUpdate from "./ajax/reducers/group/group-put";
import groupDelete from "./ajax/reducers/group/group-delete";
import groupList from "./components/modules/groups/list/GroupList-Reducer";
import groupForm from "./components/modules/groups/form/GroupForm-Reducer";

import getDeletionRequestsReducer from "./ajax/reducers/deletion-request/deletion-requests-get";
import postDeletionRequestConfirmReducer from "./ajax/reducers/deletion-request/deletion-request-confirm-post";
import deleteRequestListReducer from "./../src/components/modules/delete-request/list/DeleteRequestList-Reducer";
import userOTPLoginReducer from "./ajax/reducers/user/user-otp-login-post";
import userOTPLoginValidateReducer from "./ajax/reducers/user/user-otp-login-validate-post";
import getDocumentsReducer from "./ajax/reducers/document/documents-get";
import getDocumentFileDownloadReducer from "./ajax/reducers/document/document-file-download-get";
import getCategoriesReducer from "./ajax/reducers/category/categories-get";
import postCategoryReducer from "./ajax/reducers/category/category-post";
import deleteCategoryReducer from "./ajax/reducers/category/category-delete";
import getCategoryReducer from "./ajax/reducers/category/category-get";
import putCategoryReducer from "./ajax/reducers/category/category-put";

import categoryListReducer from "./components/modules/category/list/CategoryList-Reducer";
import getFoldersReducer from "./ajax/reducers/document/folders/folders-get";
import getFolderReducer from "./ajax/reducers/document/folders/folder-get";
import postFolderReducer from "./ajax/reducers/document/folders/folder-post";
import putFolderReducer from "./ajax/reducers/document/folders/folder-put";
import deleteFolderReducer from "./ajax/reducers/document/folders/folder-delete";
import folderReducer from "./components/modules/document/folder/Folder-Reducer";
import deleteFileReducer from "./ajax/reducers/document/file/file-delete";
import uploadFilesReducer from "./ajax/reducers/document/file/files-upload";
export default combineReducers({
  app,
  routes,
  loginForm,
  main,

  userLogin,
  detailsUser,
  userLogOut,
  userOTPLoginReducer,
  userOTPLoginValidateReducer,

  getDocumentsReducer,
  getDocumentFileDownloadReducer,

  //categories reducers
  getCategoriesReducer,
  getCategoryReducer,
  postCategoryReducer,
  putCategoryReducer,
  deleteCategoryReducer,

  //folders
  getFoldersReducer,
  getFolderReducer,
  postFolderReducer,
  putFolderReducer,
  deleteFolderReducer,
  deleteFileReducer,
  uploadFilesReducer,

  userUpdate,
  accountForm,
  usersGet,
  allUsersGet,
  userGet,
  userCreate,
  userDelete,
  userList,
  userForm,
  myAccountUpdate,
  updateBulkUsers,
  bulkUserUpdateForm,
  userApprove,
  userStatsGet,
  //passwordForm,

  rolesGet,
  roleList,
  roleForm,
  roleCreate,
  roleUpdate,
  roleDelete,
  roleGet,

  allGroupsGet,
  groupsGet,
  groupList,
  groupForm,
  groupCreate,
  groupUpdate,
  groupDelete,
  groupGet,

  getDeletionRequestsReducer,
  postDeletionRequestConfirmReducer,
  deleteRequestListReducer,
  categoryListReducer,

  folderReducer,
});
