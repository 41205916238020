import React from "react";

import { loginToken } from "../../../utils/LocalStorageUtils";

import axios from "axios";
import { apiBaseUrl } from "../../../utils/URLUtils";
import ActionPopover from "../../common/ActionPopover/ActionPopover";
import { Popover } from "react-bootstrap";
import logoW from "../../../assets/images/more-option-icon.svg";
const DocumentFile = ({ file, deleteFileAction }) => {
  const area = React.useMemo(() => {
    return process.env.REACT_APP_AREA;
  }, []);

  const { file_original_name, id } = file;

  const downloadFile = async (e, id, fileName) => {
    e.preventDefault();
    try {
      const response = await axios.get(apiBaseUrl(`download/file/${id}`), {
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
        responseType: "blob", // This ensures the file is returned as a Blob
      });

      // Create a URL for the Blob
      const fileURL = URL.createObjectURL(new Blob([response.data]));

      // Create a link element
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", fileName); // Set the download attribute
      document.body.appendChild(link);
      link.click(); // Trigger the download
      document.body.removeChild(link); // Clean up
      URL.revokeObjectURL(fileURL); // Free up memory
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <div className="single-list">
      <div className="file-name">
        <span className="icon-doc"></span> {file_original_name}
      </div>

      <div className="action-block">
        {area !== "member" && (
          <ActionPopover
            popover={
              <Popover id={`action-popover-file-${id}`}>
                <Popover.Body>
                  <span
                    type="button"
                    className="file-delete-btn"
                    onClick={() => deleteFileAction(id)}
                  >
                    Ta bort filen
                  </span>
                </Popover.Body>
              </Popover>
            }

            // label={<div className='popover-toggle-btn'><span><img src={logoW} /></span></div>}
          />
        )}
        <div className="link-block">
          <a href="#" onClick={(e) => downloadFile(e, id, file_original_name)}>
            Ladda Ner
          </a>
        </div>
      </div>
    </div>
  );
};

export default DocumentFile;
