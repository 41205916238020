import React, { memo } from "react";
import { Link } from "react-router-dom";
import MyIcon from "../icons/MyIcon";
import GrowingLoader from "../../common/loaders/GrowingLoader";
import { useSelector } from "react-redux";

const ActionCell = (props) => {
  const reduxState = useSelector((state) => state);
  const { detailsUser } = reduxState;
  const user_role =
    detailsUser &&
    detailsUser.success &&
    detailsUser.success.data &&
    detailsUser.success.data.role &&
    detailsUser.success.data.role.slug;
  const user_id =
    detailsUser &&
    detailsUser.success &&
    detailsUser.success.data &&
    detailsUser.success.data.id;
  let {
    id,
    status,
    url,
    deleteReducer,
    approveReducer,
    statusChange,
    view,
    onStatusConfirm,
    onDelete,
    role,
  } = props;

  return (
    <>
      <Link to={`${url}/${id}/update`}>
        <button className="btn btn-secondary btn-sm" data-tip="Edit">
          <MyIcon type="edit" />
        </button>
      </Link>

      {user_role === "admin" && role?.slug !== "avliden" && id !== user_id
        ? statusChange && (
            <button
              type="button"
              className={`btn  btn-sm ml-1 ${
                status === "pending" ? `btn-success` : `btn-danger`
              }`}
              onClick={() => onStatusConfirm(status, id)}
              data-tip="Toggle"
            >
              {approveReducer.processing && approveReducer.id === id ? (
                <GrowingLoader />
              ) : status === "pending" ? (
                <MyIcon type="check" />
              ) : (
                <MyIcon type="times" />
              )}
            </button>
          )
        : null}

      {view && (
        <Link to={`${url}/${id}/view`}>
          <button className="btn btn-warning btn-sm ml-1" data-tip="View">
            <MyIcon type="eye" />
          </button>
        </Link>
      )}

      {user_role === "admin" ? (
        <button
          type="button"
          className="btn btn-danger btn-sm ml-1"
          onClick={() => onDelete(id)}
          data-tip="Delete"
        >
          {deleteReducer.processing && deleteReducer.id === id ? (
            <GrowingLoader />
          ) : (
            <MyIcon type="trash" />
          )}
        </button>
      ) : null}

      {/*<ReactTooltip backgroundColor="#043B86"/>*/}
    </>
  );
};
export default memo(ActionCell);
