import React from "react";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import getFolders from "../../../../ajax/actions/document/folder/folders_get";
import deleteFolder from "../../../../ajax/actions/document/folder/folder_delete";
import DocumentCard from "../DocumentCard";
import Filter from "./Filter";
import FolderForm from "./FolderForm";
import { setCategories, setFolderUpdateModal } from "./Folder-Actions";
import getCategories from "../../../../ajax/actions/category/categories_get";
import Accordion from "../../../common/Accordion";
import GrowingLoader from "../../../common/loaders/GrowingLoader";
import ModalDialog from "../../../common/Modal";
import "./document.scss";
import Page from "../../../shared/pages/Page";

const Folder = () => {
  const dispatch = useDispatch();
  const [folders, setFolders] = React.useState([]);
  const [deleteFolderID, setDeleteFolderID] = React.useState("");

  const reduxState = useSelector((state) => state);
  const {
    getFoldersReducer,
    folderReducer,
    getCategoriesReducer,
    deleteFolderReducer,
  } = reduxState;

  const { folderModalOpen } = folderReducer.data.payload;

  React.useEffect(() => {
    dispatch(getFolders());
    dispatch(getCategories(100));
  }, []);

  React.useEffect(() => {
    if (getCategoriesReducer.success) {
      dispatch(setCategories(getCategoriesReducer.success.data.data));
    }
  }, [getCategoriesReducer.success]);

  React.useEffect(() => {
    if (getFoldersReducer.success) {
      setFolders(getFoldersReducer.success.data);
    }
  }, [getFoldersReducer.success]);

  const deleteFolderById = (folders, folderId) => {
    return folders.reduce((acc, folder) => {
      if (folder.id === folderId) return acc; // Skip the folder to delete

      const updatedFolder = { ...folder };
      if (updatedFolder.children && updatedFolder.children.length) {
        updatedFolder.children = deleteFolderById(
          updatedFolder.children,
          folderId
        );
      }

      acc.push(updatedFolder);
      return acc;
    }, []);
  };

  React.useEffect(() => {
    if (deleteFolderReducer.success) {
      setFolders((prev) => deleteFolderById(prev, deleteFolderID));
      setDeleteFolderID("");
    }
  }, [deleteFolderReducer.success]);

  const onDeleteFolder = (id) => {
    swal("Är du säker på att du vill radera denna folder? ", {
      buttons: {
        cancel: "Avbryt",
        catch: {
          text: "Radera",
          value: id,
        },
      },
    }).then((value) => {
      if (value) {
        setDeleteFolderID(id);
        dispatch(deleteFolder(id));
      }
    });
  };

  const handleClose = () => {
    dispatch(setFolderUpdateModal(""));
  };

  return (
    <Page title="Dokument & Information">
      <div className="Page animated fadeIn faster">
        {/* <DocumentHeader /> */}

        <div className="document-info-block main-text-block m-auto">
          <div className="title-block text-center">
            <h2>Dokument & Information</h2>
          </div>

          <Accordion
            children={<FolderForm folderData={folders} />}
            title="Ny mapp"
            id="accordion-folder-create"
            collapseID="collapse-new-folder"
            className="new-folder"
          />

          <Accordion
            children={<Filter />}
            title="Filtrera"
            id="accordion-folder-filter"
            collapseID="collapse-filter-folder"
            className="new-filter"
          />

          <div className="accordion-block" id="accordion1">
            {getFoldersReducer.processing ? (
              <GrowingLoader />
            ) : folders.length ? (
              folders.map((item, key) => {
                return (
                  <>
                    <DocumentCard
                      document={item}
                      key={key}
                      count={key}
                      hasAction
                      deleteAction={onDeleteFolder}
                      deleteFolderID={deleteFolderID}
                    />
                  </>
                );
              })
            ) : (
              <h4>No document available.</h4>
            )}
          </div>
        </div>
        <ModalDialog
          title="Redigera mapp"
          content={<FolderForm folderData={folders} />}
          show={folderModalOpen}
          handleClose={handleClose}
          className={"document-info-block"}
        />
      </div>
    </Page>
  );
};

export default Folder;
