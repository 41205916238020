import React, { Suspense, lazy, useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
// import Main from "../Main/Main";
import { history } from "../../../store";
import { loggedIn, loginEmail } from "../../../utils/LocalStorageUtils";
import Div from "../../common/Div/Div";
// import Login from "../../modules/login/LoginForm";
import "../../../../node_modules/animate.css/animate.css";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/jelly.css";
import Alert from "react-s-alert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css"; // For styles
import "jquery"; // Required by Bootstrap 4 JS
import "popper.js"; // Required by Bootstrap 4 JS
import "bootstrap/dist/js/bootstrap.min.js"; // Bootstrap JS
import SuspenseLoader from "../../shared/loaders/SuspenseLoader";
import MemberLoginForm from "../../modules/login/member/MemberLoginForm";
import BankLoginForm from "../../modules/login/member/BankLoginForm";
import AdminBankLoginForm from "../../modules/login/adminBankLoginForm/BankLoginForm";
import MemberOTPLoginForm from "../../modules/login/member/MemberOTPLogin";
import { endOfURL } from "../../../utils/URLUtils";
// import useScreenResize from "../../hooks/useScreenResize";
require("dotenv").config();

const Main = React.lazy(() => import("../Main/Main"));
const Login = React.lazy(() => import("../../modules/login/LoginForm"));
toast.configure();
const App = () => {
  // useScreenResize();

  const area = React.useMemo(() => {
    return process.env.REACT_APP_AREA;
  }, []);

  React.useEffect(() => {
    if (area === "member") {
      document.body.classList.add("new-design");
    }

    if (!loginEmail() && endOfURL() === "login-otp") {
      history.push("/login");
    }
  }, []);

  return (
    <Div className="App">
      <Router history={history}>
        <Suspense fallback={<SuspenseLoader />}>
          {!loggedIn() ? (
            area === "admin" ? (
              <Switch>
                <Route
                  key="/login-bank"
                  path="/login-bank"
                  component={AdminBankLoginForm}
                />
                <Route key="/login" path="/" component={Login} />
                <Route exact key="/login" path="/login" component={Login} />
              </Switch>
            ) : (
              <Switch>
                <Route
                  key="/login-bank"
                  path="/login-bank"
                  component={BankLoginForm}
                />
                <Route
                  key="/login-otp"
                  path="/login-otp"
                  component={MemberOTPLoginForm}
                />
                <Route key="/login" path="/" component={MemberLoginForm} />
                <Route
                  exact
                  key="/login"
                  path="/login"
                  component={MemberLoginForm}
                />
              </Switch>
            )
          ) : (
            <Main />
          )}
        </Suspense>
      </Router>
      <Alert stack={{ limit: 1 }} />
    </Div>
  );
};

export default App;
