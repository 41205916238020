import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { loginToken } from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'

function _success(success)
{
    return { type: 'PUT_CATEGORY_SUCCESS', success };
}
function _error(error)
{
    return { type: 'PUT_CATEGORY_ERROR', error };
}
function _processing(processing)
{
    return { type: 'PUT_CATEGORY_PROCESSING', processing };
}

function updateCategory(values, id )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
         axios({
            url: apiBaseUrl(`category/${id}`),
            method: "put",
            dataType: 'json',
            data: values,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess( res  )

            }).catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error )
            });
    }
}

export function updateCategoryReset()
{
    return dispatch => dispatch({ type: `PUT_CATEGORY_RESET` })
}
export default updateCategory;