export function setDefaultUserForm() {
  return function (dispatch){
    dispatch({type:'SET-DEFAULT-USER-FORM'});
  }
}

export function setUserDataForUpdate()
{
  return function (dispatch, getState) {
    let { userGet } = getState();
    let user = userGet && userGet.success && userGet.success.data;
    dispatch({type:'SET-USER-DATA-FOR-UPDATE',  users: user });
  }
}