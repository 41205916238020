import React, { memo } from "react";
import { names } from "../../../utils/StringUtils";
import Div from "../Div/Div";
import Select from "react-select";
import clsx from "clsx";

const ReactSelectField = ({
  name,
  options,
  onChange,
  label,
  touched,
  error,
  className,
  value,
  processing,
  disabled,
  isClearable = false,
  isSearchable = false,
  placeholder = "Select...",
}) => {
  const hasError = error && touched;

  return (
    <Div className={names(`form-group`, hasError && `has-error`)}>
      <label className={clsx("input__label", { disabled })}>{label}</label>
      <Select
        className={names(`select-field`, className)}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled || processing}
        placeholder={placeholder}
        isDisabled={disabled}
        isLoading={processing}
        isClearable={isClearable}
        isSearchable={isSearchable}
        options={options}
      />
    </Div>
  );
};
export default memo(ReactSelectField);
