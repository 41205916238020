import React from "react";
// import Home from "../home/Home";
import Account from "../account/AccountForm";
import UserForm from "../users/form/UserForm";
import Users from "../users/list/UserList";
import Roles from "../roles/list/Roles";
import RoleForm from "../roles/form/RoleForm";
import BulkUserUpdateForm from "../users/bulk-update-form/BulkUserUpdateForm";
import UserDetail from "../users/details/UserDetail";
import PendingUsers from "../users/list/PendingUserList";
import JourNummer from "../jour-nummer/JourNummer";
import DeleteRequests from "../delete-request/list/DeleteRequestList";
import Category from "../category/list/Category";
import CategoryForm from "../category/form/CategoryForm";
import Groups from "../groups/list/Groups";
import GroupForm from "../groups/form/GroupForm";

import Folder from "../document/folder";

const route = (path, title, exact, component) => ({
  path,
  title,
  exact,
  component,
});

export const RoutesState = {
  data: {
    list: [
      // route( `/`, `Home`,'exact', <Account/> ),
      route(`/`, `Home`, "exact", <Users />),
      route(`/account`, `Account`, "exact", <Account />),
      route(`/users/create`, `User Create`, "exact", <UserForm />),
      route(
        `/pending-users/create`,
        `Pending User Create`,
        "exact",
        <UserForm />
      ),
      route(
        `/users/:id([0-9]{0,9})/update`,
        `User Update`,
        "exact",
        <UserForm />
      ),
      route(
        `/pending-users/:id([0-9]{0,9})/update`,
        `Pending User Update`,
        "exact",
        <UserForm />
      ),
      route(
        `/users/:id([0-9]{0,9})/view`,
        `User Details`,
        "exact",
        <UserDetail />
      ),
      route(
        `/pending-users/:id([0-9]{0,9})/view`,
        `User Details`,
        "exact",
        <UserDetail />
      ),
      route(`/users`, `Users List`, "exact", <Users />),
      route(`/pending-users`, `Pending Users`, "exact", <PendingUsers />),
      route(`/roles`, `Role`, "exact", <Roles />),
      route(`/profile/:date(date)`, `Role`, "exact", <JourNummer />),
      route(`/roles/create`, `Role Create`, "exact", <RoleForm />),
      route(
        `/roles/:id([0-9]{0,9})/update`,
        `Role Update`,
        "exact",
        <RoleForm />
      ),
      route(
        `/users/bulk-update`,
        `Bulk Update`,
        "exact",
        <BulkUserUpdateForm />
      ),
      route(
        `/deletion-request`,
        `Account Delete Request`,
        "exact",
        <DeleteRequests />
      ),
      route(`/category`, `Category`, "exact", <Category />),
      route(`/category/create`, `Category Form`, "exact", <CategoryForm />),
      route(
        `/category/:id([0-9]{0,9})/update`,
        `Category Form`,
        "exact",
        <CategoryForm />
      ),
      route(`/document`, `Document`, "exact", <Folder />),
      route(`/groups`, `Groups`, "exact", <Groups />),
      route(`/groups/create`, `Group Create`, "exact", <GroupForm />),
      route(
        `/groups/:id([0-9]{0,9})/update`,
        `Group Update`,
        "exact",
        <GroupForm />
      ),
    ],
  },
};
