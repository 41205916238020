import { toast } from "react-toastify";

export const TheApp = {
  Engine: {
    Production: true,
    // Production: false,
  },
};

export const ConfigApp = {
  Name: `OISF`,
};

export const API = {
  BaseURL: `http://185.157.222.218/`,
  AuthBaseURL: `http://46.21.101.235/`,
  // FileUploadBaseURL: `https://api.kraken.io/v1/upload`
};

export const YearLimit = {
  limit: 50,
  start: 1980,
};

export const Colors = {
  LightBlue: `#568EB2`,
  Blue: `#108ee9`,

  Green: `#0C755B`,
  LightGreen: `#87d068`,

  Red: `#EC5143`,
  DarkRed: `#F80000`,

  Gray: `#ccc`,
};

export const Modal = {
  MaskStyle: {
    backgroundColor: `rgba(0, 0, 0, .2)`,
  },
};

export const maximumModalWidth =
  window.innerWidth >= 1500
    ? 1150
    : window.innerWidth >= 1366
    ? 1024
    : window.innerWidth >= 1024
    ? 768
    : window.innerWidth >= 768
    ? 691
    : window.innerWidth >= 576
    ? 518
    : window.innerWidth >= 432
    ? 389
    : 292;

export const ConfigErrorAlert = {
  position: "top-right",
  effect: "jelly",
  timeout: 4000,
  // beep: errorSound,
  stack: 1,
};
export const ConfigSuccessAlert = {
  position: "top-right",
  effect: "jelly",
  timeout: 4000,
  beep: false,
  stack: 1,
};
export const ConfigToastAlert = {
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: true,
  limit: 1,
  autoClose: 4000,
};
export const ConfigSider = {
  MenuItems: {
    "super-admin": [
      { link: `/users`, label: `Godkända medlemmar` },
      { link: `/pending-users`, label: `Inväntande medlemmar` },
      { link: `/roles`, label: `Behörighet` },
      { link: `/users/bulk-update`, label: `Importera medlemmar` },
      { link: `/deletion-request`, label: `Begäran om borttagning av konto` },
      { link: `/category`, label: `Kategorier` },
    ],
    admin: [
      { link: `/users`, label: `Godkända medlemmar` },
      { link: `/pending-users`, label: `Inväntande medlemmar` },
      { link: `/roles`, label: `Behörighet` },
      { link: `/users/bulk-update`, label: `Importera medlemmar` },
      { link: `/deletion-request`, label: `Begäran om borttagning av konto` },
      { link: `/document`, label: `Dokument & Information ` },
      { link: `/category`, label: `Kategorier` },
      { link: `/groups`, label: `Grupp` },
    ],
    member: [
      { link: `/account`, label: `Mitt konto` },
      { link: `/document`, label: `Dokument & Information ` },
    ],
  },
};
