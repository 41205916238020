import React, { memo } from "react";
import newloginBg from "../../../../assets/images/ocean-oisf.png";

import bankIDs from "../../../../assets/images/bankid-black.svg";
import bankQR from "../../../../assets/images/bank-qr.svg";
import { Link } from "react-router-dom";

import LoginFormWorker from "../LoginFormWorker";
import axios from "axios";
import { apiBaseUrl } from "../../../../utils/URLUtils";
import { handleError } from "../../../../utils/ErrorUtils";
import Loading from "../../../shared/loaders/Loading";
import "./style.scss";
import SaveButton from "../../../shared/buttons/SaveButton";

const BankLoginForm = () => {
  const background = {
    backgroundImage: `url(${newloginBg})`,
  };

  const [qr, setQr] = React.useState("");
  /** flag to check whether the bankid is authorized or not to fetch the collect API continuously  */
  const [bankAuth, setBankAuth] = React.useState(false);
  const [orderRef, setOrderRef] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  /** collection status checks for the status whether success is false or true, false success stops the API call and render to login */
  const [collectionStatus, setCollectionStatus] = React.useState();
  /**  status for checking whether API calls reaches to 30sec or collectionStatus is failed, if false it replace qr and display login fail  */
  const [loginFailStatus, setLoginFailStatus] = React.useState(true);
  /** redirectUrl if qr is not available */
  const [redirectUrl, setRedirectUrl] = React.useState("");
  /** accessToken when success bank authorizes */
  const [authToken, setAuthToken] = React.useState("");

  const collectionOrderRef = () => {
    axios({
      url: apiBaseUrl("bankid/collect"),
      method: "post",
      dataType: "json",
      data: { orderRef },
    })
      .then((res) => {
        if (res) {
          const { apiCallResponse, authResponse, token } = res.data;
          setCollectionStatus(apiCallResponse.Success);
          setBankAuth(true);
          token?.access_token && setAuthToken(token?.access_token);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  React.useEffect(() => {
    setLoading(true);
    axios({
      url: apiBaseUrl("bankid/auth"),
      method: "post",
    })
      .then((res) => {
        if (res) {
          setQr(res.data?.qrImage);
          setOrderRef(res.data?.orderRef);
          setRedirectUrl(res.data?.redirectUrl);
          setBankAuth(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        handleError(error);
        setLoading(false);
      });

    return () => {
      setBankAuth(false);
    };
  }, []);

  React.useEffect(() => {
    if (bankAuth) {
      const interval = setInterval(function () {
        if (orderRef) collectionOrderRef();
        if (qr) {
          setQr((prev) => prev + `?timestamp=${new Date().toLocaleString()}`);
        }
      }, 2000);

      if (collectionStatus === false) {
        clearTimeout(interval);
        setQr("");
        setBankAuth(false);
        setLoginFailStatus(false);
      }

      if (authToken) {
        clearTimeout(interval);
        setQr("");
        setBankAuth(false);
        setLoginFailStatus(true);
        localStorage.setItem("Bearer", authToken);
        window.location.href = "/";
      }

      setTimeout(function () {
        clearTimeout(interval);
        setQr("");
        setBankAuth(false);
        setLoginFailStatus(false);
      }, 30000);

      // Clear interval on component unmount
      return () => {
        clearTimeout(interval);
      };
    }
  }, [bankAuth, collectionStatus, qr, orderRef]);

  return (
    <section className={`login-page bank-login-form`}>
      <div className="container-fluid p-0 h-100">
        <div className="row h-100 align-items-center no-gutters">
          <div className="col-lg-8 h-100 d-none d-lg-block">
            <div className="logo-block h-100">
              <div className="image-block h-100 w-100" style={background} />
              <div className="content-block">
                <h1>OISF</h1>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="login-form">
              <div className="login-logo d-block ">
                <h3>Mobile BankId </h3>
              </div>
              <div className="bank-id-logo text-center">
                <img src={bankIDs} alt="bank-id" />
              </div>
              <div className="bank-qr-warpper">
                {loading ? (
                  <Loading xs />
                ) : !loginFailStatus ? (
                  <LoginFailed />
                ) : redirectUrl ? (
                  <RedirectURL url={redirectUrl} />
                ) : (
                  <img src={qr} alt="bank-qr" />
                )}
              </div>

              <div className="content">
                <p>
                  Starta BankID-appen i din mobil och tryck pa Skanna QR-kod.
                  Rikta kameran mot QR-koden har ovanfor.
                </p>
              </div>

              <div className="login-back-btn text-center">
                <Link to="/login">Avbryt inloggning</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoginFormWorker />
    </section>
  );
};

export default memo(BankLoginForm);

const LoginFailed = () => {
  return (
    <div className="login-fail-wrapper">
      <h4>Login Failed</h4>
    </div>
  );
};

const RedirectURL = ({ url }) => {
  return (
    <a href={url}>
      <SaveButton text="Start Your Bank ID App" />
    </a>
  );
};
