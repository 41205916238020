import React, { memo } from 'react'
import loader from '../../../assets/images/loader.svg'
import whiteLoader from '../../../assets/images/loader-white.svg'

const Loading = (props) => {

    let {
        color, height, width,
        xs, sm, md, lg, xl,
    } = props;

    let setHeight =  xs ? 15 : sm ? 25 : md ? 50 : lg ? 80 : xl ? 200 : 25;
    let setWidth =  xs ? 15 : sm ? 25 : md ? 50 : lg ? 80 : xl ? 200 : 25;
  
    return (

        <>
            <img src={color === `white` ? whiteLoader : loader}
                 alt="loader"
                 height={height ? height : setHeight}
                 width={width ? width : setWidth}
            />
        </>
    );
}

export default memo(Loading )