const DefaultUserListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    sort_by:  'desc',
    sort_column: `id`,
    status:`approved`,
    q: ``,
    role_id: ``,
  },
  modal: {
    mode: ``,
    id: ``
  },
  fields: [
    // { field: `id`, label: `ID`, sort: true },
    // { field: `username`, label: `Användarnamn`, sort: true },
    { field: `first_name`, label: `Förnamn`, sort: true },
    { field: `last_name`, label: `Efternamn`, sort: true },
    // { field: `role`, label: `Role`, sort: true },
    { field: `address`, label: `Gatuadress`, sort: true },
    { field: `postal_code`, label: `Postnummer`, sort: true },
    { field: `post_address`, label: `Postort`, sort: true },
    { field: `email`, label: `E-post`, sort: true },
    { field: `personal_number`, label: `Personnummer`, sort: true },
    { field: `offk`, label: `Offk`, sort: true },
    { field: `forb`, label: `Forb`, sort: true },
    { field: `grad`, label: `Grad`, sort: true },
    { field: `note`, label: `Notering`, sort: true },
    { field: `approval_date`, label: `Godkänd`, sort: true },
    // { field: `samtycke`, label: `Samtycke`, sort: true },
    // { field: `company_name`, label: `Company`, sort: true },
    // { field: `state`, label: `State`, sort: true },
    // { field: `city`, label: `City`, sort: true },
    // { field: `postcode`, label: `Post Code`, sort: true },
    { field: `status`, label: `Status`, sort: true },

  ]
};

export const UserListState = { data: DefaultUserListState };