import axios from "axios";
import { apiBaseUrl } from "../../../utils/URLUtils";
import { loginToken } from "../../../utils/LocalStorageUtils";
import { handleError } from "../../../utils/ErrorUtils";
import { clone } from "../../../utils/DataUtils";

function _success(success) {
  return { type: "GET_GROUPS_SUCCESS", success };
}

function _error(error) {
  return { type: "GET_GROUPS_ERROR", error };
}

function _processing(processing) {
  return { type: "GET_GROUPS_PROCESSING", processing };
}

function getGroups(limit) {
  return (dispatch, getState) => {
    dispatch(_processing(true));
    let { groupList } = getState();

    let params = clone(groupList.data.params);
    if (limit) params.limit = limit;

    axios({
      url: apiBaseUrl(`group`),
      method: "get",
      params,
      headers: {
        Authorization: "Bearer " + loginToken(),
      },
    })
      .then(function (res) {
        dispatch(_success(res));
        dispatch(_processing(false));
      })
      .catch(function (error) {
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError(error);
      });
  };
}

export default getGroups;
