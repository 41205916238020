import React from 'react';

class MyIcon  extends React.Component
{
    render()
    {
        let { type, className, onClick, width, color, dataTip  } = this.props;
        return (

            <i className={`icon fa fa-${type} ${className}`}
               onClick={onClick}
               data-tip={ dataTip }
               style={{ fontSize: width || null, color: color || null }}
            />

        )
    }
}
export default MyIcon;