import React, {useEffect, useState } from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import UploadField from "../../../shared/form/UploadField";
import SaveButton from "../../../shared/buttons/SaveButton";
import sampleCSV from '../../../../assets/csv/sample_users.csv'
import {isArray} from "../../../../utils/DataUtils";
import {useFormik} from "formik";
import * as Yup from "yup";
import bulkUsersUpdate, {bulkUserUpdateReset} from "../../../../ajax/actions/user/bulk_user_update_post";
import {useHistory} from "react-router";
// import UploadImage from "../../../common/form/UploadImage";
import UploadImageNew from "../../../common/form/UploadImageNew";
import ProgressBar from "../../../common/loaders/ProgressBar";
const BulkUserUpdateForm = () =>
{
    const [ uploadKey, setUploadKey ] = useState(Math.random().toString(36));
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { updateBulkUsers, bulkUserUpdateForm } = reduxState;
    const history = useHistory();
    const [completed, setCompleted] = useState(0);

    useEffect( () => {
        return () => {
            setCompleted(0);
        }
    }, []);

    useEffect( ()=>{
        if( updateBulkUsers.success ) {
            dispatch(bulkUserUpdateReset());
            clearInterval();
            history.push('/users');
            setCompleted(100);
        }
    }, [updateBulkUsers.success])

    const fields = {...bulkUserUpdateForm.data};
    useEffect(() => {
        if( updateBulkUsers.processing ) {
            setCompleted(updateBulkUsers.progress)
            // setInterval(() => setCompleted(Math.floor(Math.random() * 100) + 1), 20)
        }

    }, [updateBulkUsers]);

    const {handleChange, handleSubmit, errors, touched, values, setFieldValue,} = useFormik(
        {
            initialValues: fields,
            enableReinitialize: true,

            validationSchema: Yup.object().shape({
                data_file: Yup.string().required(` is required`),
            }),

            onSubmit: (values) => {
                dispatch(bulkUsersUpdate( values ))
            }
        });

    function randomString() {
        setUploadKey( Math.random().toString(36) );
    }

    const removeFiles = (e)  => {
        
        setFieldValue(`data_file`, ``);
        randomString()
    }

    const handleFiles = (e)  => {
        
        setFieldValue(`data_file`, e.target.value);
    }

    const {  processing, error } = updateBulkUsers;
    // const processing = true;
    /*let import400Errors =
        error &&
        error.response.data.payload.errors;*/

    return (

        <div className="row">
            <div className="col-md-8 col-lg-7 col-sm-12 offset-md-1 offset-lg-1">

                <h2>Importera medlemmar</h2>

                {/*{ isArray( import400Errors ) && import400Errors.length ?
                    <div className="alert alert-danger" role="alert">
                        <div className="mb-1">
                            <b>Error! Couldn't import the provided dataset. Please fix the following error and try again.</b>
                        </div>
                        { import400Errors.map( err => { return (
                            <div className="mb-1"><u>Row 3</u> - { err.errors.map( er => { return (
                                <>{ er }</>
                            )}) }</div>
                        )}) }
                    </div>
                    : null }*/}

                <form onSubmit={handleSubmit} id="bulk-user-update-form">

                    {/*<UploadField name="data_file"
                                 value={values.data_file}
                                 error={ errors && errors.data_file }
                                 touched={ touched.data_file }
                                 onChange={handleChange}
                                 onRemoveImage={ removeFiles }
                                 // multiple
                                 supportedFormats=".csv"

                    />*/}
                    {processing && <ProgressBar completed={completed} />}
                    <UploadImageNew name="data_file"
                                    keys={ uploadKey }
                                 value={ values.data_file}
                                 error={errors && errors.data_file}
                                 touched={touched.data_file}
                                 onChange={handleFiles}
                                 onRemoveImage={removeFiles}
                                 supportedFormats=".csv"
                    />

                    <a target="_blank" href={ sampleCSV }><i><u>Ladda ner CSV-exempel</u></i></a>

                    <div className="text-center">
                        <SaveButton btnClass="text-uppercase ml-auto mt-5"
                                    text="Importera"
                                    type="submit"
                                    processing={processing} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default  BulkUserUpdateForm;