import React from "react";
import TextField from "../../../shared/form/TextField";
import TextAreaField from "../../../common/form/TextAreaField";
import UploadImageNew from "../../../common/form/UploadImageNew";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import createFolder, {
  createFolderReset,
} from "../../../../ajax/actions/document/folder/folder_create";
import updateFolder, {
  updateFolderReset,
} from "../../../../ajax/actions/document/folder/folder_update";
import getFolder from "../../../../ajax/actions/document/folder/folder_get";
import SaveButton from "../../../shared/buttons/SaveButton";
import { setFolderModalClose } from "./Folder-Actions";
import NewSelect from "../../../shared/form/NewSelect";
import createCategory, {
  createCategoryReset,
} from "../../../../ajax/actions/category/category_post";
import getRoles from "../../../../ajax/actions/role/roles_get";
import getAllGroups from "../../../../ajax/actions/group/groups_all_get";
import MultiSelectField from "../../../common/form/MultiSelectField";
import ReactSelectField from "../../../common/form/ReactSelectField";

const DocumentFolder = ({ folderData }) => {
  const [uploadKey, setUploadKey] = React.useState(Math.random().toString(36));
  const [payload, setPayload] = React.useState({
    name: "",
    category_id: "",
    description: "",
    group_ids: [],
    parent_id: "",
    files: [],
  });

  //addon is category listing
  const [addon, setAddon] = React.useState("");
  const [groups, setGroups] = React.useState([]);
  const [folders, setFolders] = React.useState([]);
  const [selectedGroup, setSelectedGroup] = React.useState([]);

  const fileInputRef = React.useRef(null);

  const dispatch = useDispatch();

  const reduxState = useSelector((state) => state);
  const {
    folderReducer,
    postFolderReducer,
    getFolderReducer,
    putFolderReducer,
    postCategoryReducer,
    allGroupsGet,
  } = reduxState;

  const { categories, folderModalID, folderModalOpen } =
    folderReducer.data.payload;

  const { handleSubmit, errors, touched, values, setFieldValue } = useFormik({
    initialValues: payload,
    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      name: Yup.string().required(` is required`),
      category_id: Yup.string().required(` is required`),
    }),

    onSubmit: (values) => {
      folderModalID
        ? dispatch(updateFolder(folderModalID, values))
        : dispatch(createFolder(values));
    },
  });

  const onHandleChange = (e) => {
    const { name, value, files } = e.target;

    // If the input is a file input and has files
    if (name === "files" && files && files.length > 0) {
      // Convert FileList to an array
      // const fileArray = Array.from(files);
      const fileArray = Array.from(files).map((file) => {
        // Inject `id` directly into the File object
        Object.defineProperty(file, "id", {
          value: `${Date.now()}-${Math.random()}`, // Generate a unique identifier
        });
        return file;
      });
      // Update the payload state
      setPayload((prev) => ({
        ...prev,
        [name]: [...prev.files, ...fileArray],
      }));
    } else {
      setPayload((prev) => ({
        ...prev,
        [name]: value, // Update the corresponding field
      }));
    }
  };

  const onChangeSelectField = (val) => {
    setPayload((prev) => ({
      ...prev,
      parent_id: val?.value || "",
    }));
  };

  const resetForm = () => {
    setPayload({
      name: "",
      category_id: "",
      description: "",
      group_ids: [],
      parent_id: "",
      files: [],
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setUploadKey(Math.random().toString(36));
    setSelectedGroup([]);
  };

  React.useEffect(() => {
    dispatch(getRoles(100));
    dispatch(getAllGroups());
  }, []);

  React.useEffect(() => {
    if (folderData) {
      const options = folderData.reduce((acc, ite) => {
        return [...acc, { label: ite.name, value: ite.id }];
      }, []);
      setFolders(options);
    }
  }, [folderData]);

  React.useEffect(() => {
    if (allGroupsGet.success) {
      const groups = allGroupsGet.success.data.message;
      const options = groups.reduce((acc, ite) => {
        return [...acc, { label: ite.name, value: ite.id }];
      }, []);
      setGroups(options);
    }
  }, [allGroupsGet.success]);

  React.useEffect(() => {
    if (folderModalID && folderModalOpen) {
      dispatch(getFolder(folderModalID));
    }
    if (!folderModalOpen) {
      resetForm();
    }
  }, [folderModalID, folderModalOpen]);

  React.useEffect(() => {
    if (postFolderReducer.success) {
      dispatch(createFolderReset());
      resetForm();
    }
  }, [postFolderReducer.success]);

  React.useEffect(() => {
    if (getFolderReducer.success) {
      const response = getFolderReducer.success?.data?.data;

      setPayload({
        name: response.name,
        category_id: response.category_id,
        description: response.description,
        group_ids: JSON.parse(response.group_ids),
        parent_id: response.parent_id,
        files: [],
      });

      if (response.group_ids && response.group_ids.length > 0) {
        const options = groups.reduce((acc, ite) => {
          if (response.group_ids.includes(ite.value)) {
            return [...acc, { label: ite.label, value: ite.value }];
          }
          return acc;
        }, []);
        setSelectedGroup(options);
      }
    }
  }, [getFolderReducer.success]);

  React.useEffect(() => {
    if (putFolderReducer.success) {
      dispatch(updateFolderReset());
      dispatch(setFolderModalClose(""));
      resetForm();
    }
  }, [putFolderReducer]);

  const removeFiles = (e) => {
    setPayload((prev) => ({
      ...prev,
      files: prev.files.filter((item) => item.id !== e.target.id),
    }));
    setUploadKey(Math.random().toString(36));
  };

  const addonHandleSubmit = () => {
    const values = {
      name: addon,
    };
    dispatch(createCategory(values));
  };

  React.useEffect(() => {
    if (postCategoryReducer.success) {
      dispatch(createCategoryReset());
      setAddon("");
    }
  }, [postCategoryReducer.success]);

  const processing = React.useMemo(() => {
    return postFolderReducer.processing || putFolderReducer.processing;
  }, [postFolderReducer, putFolderReducer]);

  const categoryOptions = React.useMemo(() => {
    return categories.reduce((acc, ite) => {
      acc.push({ label: ite.name, value: ite.id });
      return acc;
    }, []);
  }, [categories]);

  const handleGroupChange = (val) => {
    setSelectedGroup(val);
    const values = [];
    val &&
      val.map((ite) => {
        values.push(ite.value);
      });

    setPayload((prev) => ({
      ...prev,
      group_ids: values,
    }));
  };

  return (
    <div className="folder-block">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <TextField
              name="name"
              placeholder="Namn"
              value={values.name}
              error={errors && errors.name}
              touched={touched.name}
              onChange={(e) => onHandleChange(e)}
              label="Name"
            />
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <NewSelect
                error={errors && errors.category_id}
                touched={touched.category_id}
                onChange={(val) =>
                  setPayload((prev) => ({
                    ...prev,
                    category_id: val, // Update the corresponding field
                  }))
                }
                value={values.category_id}
                options={categoryOptions}
                name="category_id"
                label="Category"
                addonName="name"
                addonValue={addon}
                onAddonHandleSubmit={() => addonHandleSubmit()}
                onAddonChange={(e) => setAddon(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <TextAreaField
              name="description"
              placeholder="Beskrivning"
              value={values.description}
              onChange={(e) => onHandleChange(e)}
            />
          </div>

          <div className="col-md-6">
            <MultiSelectField
              name="group_ids"
              label="Grupp"
              placeholder="Välj grupp..."
              _label="label"
              _value="value"
              options={groups}
              onChange={(e) => handleGroupChange(e)}
              value={selectedGroup}
              multiple
            />
          </div>

          <div className="col-md-6">
            <ReactSelectField
              name="parent_id"
              placeholder="Välj mapp..."
              label="Folder"
              options={folders}
              // value={values.parent_id}
              value={
                folders?.find((item) => item.value === values.parent_id) || null
              } // Fix this line
              onChange={(selectedOption) => {
                onChangeSelectField(selectedOption);
              }}
              error={errors && errors.parent_id}
              touched={touched.parent_id}
              searchable
              isClearable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <UploadImageNew
              keys={uploadKey}
              ref={fileInputRef}
              multiple
              name="files"
              value={values.files}
              // id={`file-input-${Date.now()}-${Math.random()}`}
              onRemoveImage={removeFiles}
              onChange={(e) => onHandleChange(e)}
            />
          </div>
        </div>
        <div className="text-center">
          <SaveButton
            type="submit"
            btnClass="w-100"
            processing={processing}
            processingHeight={13}
            processingWidth={13}
          />
        </div>
      </form>
    </div>
  );
};

export default DocumentFolder;
