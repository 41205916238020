export default function putGroupReducer(
  state = { error: "", success: "", processing: false },
  action
) {
  switch (action.type) {
    case "PUT_GROUP_SUCCESS":
      return { ...state, ...{ success: action.success } };

    case "PUT_GROUP_ERROR":
      return { ...state, ...{ error: action.error } };

    case "PUT_GROUP_PROCESSING":
      return { ...state, ...{ processing: action.processing } };

    case "PUT_GROUP_RESET":
      return {
        ...state,
        ...{
          success: null,
          error: null,
          processing: false,
        },
      };

    default:
      return state;
  }
}
