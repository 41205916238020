import React, { useRef } from 'react';

const OtpField = ({ length = 4, onComplete, otp, setOtp }) => {
    // const [otp, setOtp] = useState(Array(length).fill(''));
    const inputRefs = useRef(Array(length).fill(null));


    const handleComplete = () => {
        onComplete && onComplete(otp.join(''));
    };
  
    const handleChange = (index, value) => {
        if (value.length > 1) return; // Prevent multiple digits

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Move focus to the next input
      if (value && index < length - 1) {
          if (inputRefs.current[index + 1]) {
              inputRefs.current[index + 1].focus();
          }
            // inputRefs && inputRefs.current[index + 1]?.focus();
        }

        // Check if all fields are filled
        if (newOtp.every((digit) => digit !== '')) {
            handleComplete(newOtp);
        }
    };
  
    const handlePaste = (e) => {
        const pastedData = e.clipboardData.getData('text').slice(0, length); // Limit to `length`
        const newOtp = Array(length).fill('');

        for (let i = 0; i < pastedData.length; i++) {
            if (!isNaN(pastedData[i])) { // Ensure it's a digit
                newOtp[i] = pastedData[i];
            }
        }

        setOtp(newOtp);

        // Autofocus the last filled input
        const lastFilledIndex = Math.min(pastedData.length, length) - 1;
        if (inputRefs.current[lastFilledIndex]) {
            inputRefs.current[lastFilledIndex].focus();
        }

        // Trigger completion if fully filled
        if (newOtp.every((digit) => digit !== '')) {
            handleComplete(newOtp);
        }
    };
    
    
    return (
        <div style={{ display: 'flex', gap: '10px' }}>
            {Array.from({ length }, (_, index) => (
              <input
                  key={index}
                  type="text"
                  maxLength={1}
                  value={otp[index]}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onPaste={(index === 0 ? handlePaste : undefined)} 
                  ref={(ref) => (inputRefs.current[index] = ref)}
                  style={{
                      width: '60px',
                      height: '60px',
                      textAlign: 'center',
                      fontSize: '1.5rem',
                  }}
                />
            ))}
        </div>
    );
};

export default OtpField;