import axios from 'axios';
import { apiBaseUrl } from "../../../utils/URLUtils";
import { loginToken, logOut } from '../../../utils/LocalStorageUtils'
import { handleError } from '../../../utils/ErrorUtils'

function _success(success)
{
  return { type: 'FETCH_USER_SUCCESS', success };
}
function _error(error)
{
  return { type: 'FETCH_USER_ERROR', error };
}
function _processing(processing)
{
  return { type: 'FETCH_USER_PROCESSING', processing };
}

function userDetails( )
{
  return ( dispatch ) =>
  {
    dispatch(_processing(true));
    dispatch(_success(null));
    dispatch(_error(null));

    axios({

      url: apiBaseUrl(`profile`),
      method: "get",
      dataType: 'json',
      headers: { 'Authorization': 'Bearer ' +loginToken() }
    })
      .then(function(res){
          dispatch(_success(res.data));
          dispatch(_processing( false ));

      }).catch(function( error ){

        if ( error && error.response &&
          error.response.status === 401  ) {
          logOut()
        } else {
          handleError( error )
          dispatch(_error(error));
          dispatch(_processing(false));
        }
      });
  }
}


export default userDetails;