import React, {useState, useEffect} from 'react';
import UserWorker from "./UsersWorker";
import {Link} from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loading from "../../../shared/loaders/Loading";
import PaginationButtons from "../../../shared/buttons/PaginationButtons";
import {
    toggleUserListFilter,
    toggleUserPaginationListFilter
} from "./UserList-Actions";
import UsersFilters from "./UsersFilters";
import NoResultsFound from "../../../shared/folder/NoResultsFound";
import Page from "../../../shared/pages/Page";
import TableHead from "../../../shared/table/TableHead";
import {apiBaseUrl} from "../../../../utils/URLUtils";
import {loginToken} from "../../../../utils/LocalStorageUtils";
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router";
import UserStats from "./UserStats";
import UserTableCell from "./UserTableCell";

const PendingUsers = () => {
    const [initialLoading, setInitialLoading] = useState(true);

    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { usersGet, userList, detailsUser } = reduxState;

    useEffect(() => {
        if (usersGet.success && initialLoading) {
            setInitialLoading(false);
        }
    }, [usersGet.success])

    const toggleSort = (field) => {
        dispatch(toggleUserListFilter({
            target: {name: `sort_column`, value: field}
        }))
    };

    let {processing} = usersGet;
    let { fields, params } = userList.data;

    let list =
        usersGet.success &&
        usersGet.success.data.data;

    let current_page = usersGet.success && usersGet.success.data && usersGet.success.data.current_page;
    let last_page = usersGet.success && usersGet.success.data && usersGet.success.data.last_page;
    let total = usersGet.success && usersGet.success.data && usersGet.success.data.total;
    let pages = {current_page, last_page, total};

    let bearer_token = loginToken();
    let export_file = apiBaseUrl(`export/user?access_token=${bearer_token}&status=pending`);
    let {url} = useRouteMatch();
    const user_role = detailsUser && detailsUser.success && detailsUser.success.data && detailsUser.success.data.role;

    
    if( url === '/' ) {
        url = "/pending-users"
    }

    fields = fields.filter( item =>item.field !== 'approval_date' );
    return (

        <Page list title="Inväntande medlemmar">

            <UserWorker status="pending"/>

            <div className="page-title">
                <div className="title-button-block d-flex align-items-center flex-wrap">
                    <h2 className="mr-4">Inväntande medlemmar {processing && <Loading sm marginLeft={20}/>}</h2>

                    <div className="button-block">
                        <Link to={`/users/create`}>
                            <button className="btn-theme btn-gray">
                                Skapa ny medlem
                            </button>
                        </Link>

                        { list && list.length ?
                            user_role && user_role.slug === 'admin' &&  <a href={export_file} rel="nofollow" target="_blank">
                                <button type="button" className="btn-theme btn-gray">Exportera medlem</button>
                            </a>
                            : null}
                    </div>
                </div>
                <UserStats/>

            </div>

            <UsersFilters status="pending"/>

            <div className="table-dashboard table-small">
                <div className="table-responsive">
                    <table className="table">

                        <TableHead fields={fields}
                                   params={params}
                                   onClick={toggleSort}/>

                        <tbody>
                        {
                            list && list.length ? list.map((l, key) => {
                                return (
                                    <tr key={key}>
                                        <UserTableCell url={url} data={l} mode="pending" />
                                    </tr>
                                )
                            }) : null
                        }
                        </tbody>

                        {list && list.length ? (
                            <tfoot>
                            <PaginationButtons pages={pages}
                                               processing={processing}
                                               fetchAction={toggleUserPaginationListFilter}/>
                            </tfoot>
                        ) : null}

                    </table>
                    {!initialLoading && (!list || !list.length) && <NoResultsFound/>}
                    {initialLoading && <div className="text-center"><Loading/></div>}

                </div>
            </div>
        </Page>
    )
}

export default PendingUsers;