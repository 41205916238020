export default function deleteGroupReducer(
  state = { error: "", success: "", processing: false },
  action
) {
  switch (action.type) {
    case "DELETE_GROUP_SUCCESS":
      return { ...state, ...{ success: action.success } };

    case "DELETE_GROUP_ERROR":
      return { ...state, ...{ error: action.error } };

    case "DELETE_GROUP_PROCESSING":
      return { ...state, ...{ processing: action.processing, id: action.id } };

    default:
      return state;
  }
}
