import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { loginToken } from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import store from '../../../store';

import getCategories from "./categories_get";

function _success(success)
{
    return { type: 'POST_CATEGORY_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_CATEGORY_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_CATEGORY_PROCESSING', processing };
}

function createCategory( values )
{
    return ( dispatch ) =>
    {
        dispatch(_processing(true));

        axios({
            url: apiBaseUrl(`category`),
            method: "post",
            dataType: 'json',
            data: values,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
        .then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
            handleSuccess(res);
            store.dispatch(getCategories());

        }).catch(function(error)
        {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError( error )
        });
    }
}
export function createCategoryReset()
{
    return dispatch => dispatch({ type: `POST_CATEGORY_RESET` })
}


export default createCategory;