import axios from 'axios';

import {handleError} from '../../../utils/ErrorUtils'
import {apiBaseUrl} from "../../../utils/URLUtils";

import { loggedInUserEmail} from "../../../utils/LocalStorageUtils";


function _success(success)
{
    return { type: 'LOGIN_OTP_MEMBER_SUCCESS', success };
}
function _error(error)
{
    return { type: 'LOGIN_OTP_MEMBER_ERROR', error };
}
function _processing(processing)
{
    return { type: 'LOGIN_OTP_MEMBER_PROCESSING', processing };
}

function loginOTPUser(values)
{
    
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true ));
        dispatch(_error(null ));
        dispatch(_success(null ));

        axios({
            url: apiBaseUrl("otp/generate/member"),
            method: "post",
            dataType: 'json',
            data: values,
        })
          .then(function(res){
              dispatch(_success(res));
              dispatch(_processing(false));
              loggedInUserEmail( values.email );
             
          }).catch(function(error){
              handleError( error );
              dispatch(_error(error));
              dispatch(_processing(false));
          });
    }
}


export default loginOTPUser;