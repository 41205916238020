const DefaultFolderState ={
  payload: {
    folderModalOpen: false,
    folderModalID:'',
    categories:[]
  },
  params: {
    category_id: [],
    q: '',
    limit: 10,
    
  }
};

export const FolderState = { data: DefaultFolderState };