export const UserFormState = {
  payload: {
    first_name: ``,
    last_name: ``,
    username: ``,
    email: ``,
    address: ``,
    // company_name:``,
    // second_address_line:``,
    postal_code: ``,
    post_address: ``,
    grad: ``,
    forb: ``,
    offk: ``,
    stand: ``,
    samtycke: false,
    personal_number: ``,
    // city:``,
    // state:``,
    // country:``,
    password: ``,
    password_confirmation: ``,
    role: ``,
    note: ``,
    payment_status: ``,
    member: ``,
    approval_date: "",
  },
};
