import axios from 'axios';
import store from '../../../store';
import {handleError} from '../../../utils/ErrorUtils'
import {apiBaseUrl} from "../../../utils/URLUtils";
// import userDetails from "./user_detail_get";
import {loggedInUser} from "../../../utils/LocalStorageUtils";
// import {setLoggedInUser} from "../../../components/layout/Main/Main-Actions";

function _success(success)
{
    return { type: 'LOGIN_USER_SUCCESS', success };
}
function _error(error)
{
    return { type: 'LOGIN_USER_ERROR', error };
}
function _processing(processing)
{
    return { type: 'LOGIN_USER_PROCESSING', processing };
}

function loginUser(values)
{   
    
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true ));
        dispatch(_error(null ));
        dispatch(_success(null ));

        axios({
            url: apiBaseUrl(`login`),
            method: "post",
            dataType: 'json',
            data: values,
        })
          .then(function(res){
              dispatch(_success(res));
              dispatch(_processing(false));
              loggedInUser( res.data );
              // store.dispatch( userDetails(  ) )
              // store.dispatch( setLoggedInUser( res.data && res.data.data ) )
              // handleSuccess(res);
          }).catch(function(error){
              handleError( error );
              dispatch(_error(error));
              dispatch(_processing(false));
          });
    }
}


export default loginUser;