import React, { memo } from "react";
import UserFormWorker from "./UserFormWorker";
import SaveButton from "../../../shared/buttons/SaveButton";
import { useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ucFirst } from "../../../../utils/StringUtils";
import { endOfURL } from "../../../../utils/URLUtils";
import Loading from "../../../shared/loaders/Loading";
import { useDispatch, useSelector } from "react-redux";
import createUser from "../../../../ajax/actions/user/user_post";
import updateUser from "../../../../ajax/actions/user/user_put";
import TextField from "../../../common/form/TextField";
import PasswordField from "../../../common/form/PasswordField";
import SelectField from "../../../common/form/SelectField";
// import {countryListAllIsoData} from "../../../../static-data/CountryList";
import {
  paymentOptions,
  samtyckeOption,
} from "../../../../static-data/LimitOptions";
// import { yearList} from "../../../../static-data/UserData";
import TextAreaField from "../../../common/form/TextAreaField";
import { scrollToError } from "../../../../utils/ScrollUtils";
import DateField from "../../../common/form/DateField";
// import NumberField from "../../../common/form/NumberField";

const UserForm = () => {
  let dispatch = useDispatch();
  const reduxState = useSelector((state) => state);
  const { userForm, userCreate, userUpdate, userGet, rolesGet } = reduxState;
  let { id } = useParams();

  let fields = { ...userForm.payload };
  const { handleChange, handleSubmit, errors, touched, values, setFieldValue } =
    useFormik({
      initialValues: fields,
      enableReinitialize: true,

      validationSchema: Yup.object().shape({
        // phone_no: Yup.number(` be number`).required('is required'),
        // status: Yup.bool().required('is required'),
        first_name: Yup.string().required(` krävs`),
        last_name: Yup.string().required(` krävs`),
        username: id && Yup.string().required(` krävs`),
        role: Yup.string().required(` krävs`),
        password: !id && Yup.string().required(` krävs`),
        email: Yup.string()
          .email(` e-postadress måste vara giltlig`)
          .required(" krävs"),
        // address:Yup.string().required(` krävs`),
        post_address: Yup.string().required(` krävs`),
        personal_number: Yup.string()
          .required("Krävs")
          .matches(/^\d{12,}$/, "Måste minst vara 12 siffror"),
        // grad:Yup.string().required(` is required`),
        // forb:Yup.string().required(` is required`),
        // offk:Yup.string().required(` is required`),
        // stand:Yup.string().required(` is required`),
        // samtycke:Yup.string().required(` is required`),
        // company_name:Yup.string().required(` is required`),
        // postcode:Yup.string().required(` is required`),
        // city:Yup.string().required(` is required`),
        // state:Yup.string().required(` is required`),
        // country:Yup.string().required(` is required`),
        password_confirmation: Yup.string().when("password", {
          is: (val) => !!(val && val.length > 0),
          then: Yup.string()
            .required("krävs")
            .oneOf([Yup.ref("password")], "Lösenorden matchar inte varandra"),
        }),
      }),

      onSubmit: (values) => {
        if (id) {
          dispatch(updateUser(values, id));
        } else {
          dispatch(createUser(values));
        }
      },
    });

  let processing = userCreate.processing || userUpdate.processing;

  let roles = rolesGet && rolesGet.success && rolesGet.success.data.data;

  const handleChangeSamtycke = (e) => {
    let { name, value } = e.target;
    let isTrueSet = value === "true";
    setFieldValue(`samtycke`, isTrueSet);
  };

  if (errors) {
    scrollToError();
  }

  return (
    <div className="row">
      <div className="offset-lg-1 col-lg-7 col-md-12">
        <h2>
          {endOfURL() === "create"
            ? "Skapa ny medlem"
            : endOfURL() === "update"
            ? "Uppdatera medlem"
            : ""}{" "}
          {userGet.processing && <Loading sm marginLeft={20} />}
        </h2>

        <form
          onSubmit={handleSubmit}
          className={userGet.processing ? `item-disabled` : null}
        >
          <TextField
            name="first_name"
            value={values.first_name}
            error={errors && errors.first_name}
            touched={touched.first_name}
            onChange={handleChange}
            label="Förnamn"
          />

          <TextField
            name="last_name"
            value={values.last_name}
            error={errors && errors.last_name}
            touched={touched.last_name}
            onChange={handleChange}
            label="Efternamn"
          />

          <TextField
            name="email"
            value={values.email}
            error={errors && errors.email}
            touched={touched.email}
            onChange={handleChange}
            label="E-post"
          />

          <TextField
            name="username"
            value={values.username}
            error={errors && errors.username}
            touched={touched.username}
            onChange={handleChange}
            label="Användarnamn"
          />

          <PasswordField
            name="password"
            value={values.password}
            error={errors && errors.password}
            touched={touched.password}
            label={
              endOfURL() === `update`
                ? `Lösenord (bara om du önskar att uppdatera detta)`
                : null
            }
            placeholder={
              endOfURL() === `update`
                ? `Lösenord (bara om du önskar att uppdatera detta)`
                : null
            }
            onChange={handleChange}
            // label="Lösenord"
          />

          <PasswordField
            name="password_confirmation"
            value={values.password_confirmation}
            error={errors && errors.password_confirmation}
            touched={touched.password_confirmation}
            label="Bekräfta lösenord"
            onChange={handleChange}
          />

          {/*<SelectField name="country"
                                options={countryListAllIsoData}
                                onChange={handleChange}
                                label="Country"
                                value={values.country}
                                _label="name"
                                _value="name"

                                error={errors && errors.country}
                                touched={touched.country}
                    />

                    <TextField name="state"
                               value={values.state}
                               error={errors && errors.state}
                               touched={touched.state}
                               onChange={handleChange}/>

                    <TextField name="city"
                               value={values.city}
                               error={errors && errors.city}
                               touched={touched.city}
                               onChange={handleChange}/>

                    */}
          <TextField
            name="postal_code"
            label="Postnummer"
            value={values.postal_code}
            error={errors && errors.postal_code}
            touched={touched.postal_code}
            onChange={handleChange}
          />

          <TextField
            name="address"
            value={values.address}
            error={errors && errors.address}
            touched={touched.address}
            onChange={handleChange}
            label="Adress"
          />

          <TextField
            name="post_address"
            value={values.post_address}
            error={errors && errors.post_address}
            touched={touched.post_address}
            onChange={handleChange}
            label="Postadress"
          />

          <TextField
            name="personal_number"
            value={values.personal_number}
            error={errors && errors.personal_number}
            touched={touched.personal_number}
            onChange={handleChange}
            label="Personnummer"
          />

          <TextField
            name="grad"
            value={values.grad}
            error={errors && errors.grad}
            touched={touched.grad}
            onChange={handleChange}
          />

          <TextField
            name="forb"
            value={values.forb}
            error={errors && errors.forb}
            touched={touched.forb}
            onChange={handleChange}
            label="Förb"
          />

          <TextField
            name="offk"
            value={values.offk}
            error={errors && errors.offk}
            touched={touched.offk}
            onChange={handleChange}
          />

          {/*<TextField name="stand"
                               value={values.stand}
                               error={errors && errors.stand}
                               touched={touched.stand}
                               onChange={handleChange}/>*/}
          {!id && (
            <SelectField
              name="samtycke"
              options={samtyckeOption}
              onChange={handleChangeSamtycke}
              label="Samtycke"
              value={values.samtycke}
              _label="label"
              _value="value"
              error={errors && errors.samtycke}
              touched={touched.samtycke}
            />
          )}

          {endOfURL() === "update" ? (
            <>
              <DateField
                name="member"
                onChange={handleChange}
                label="Member"
                value={values.member}
                error={errors && errors.member}
                touched={touched.member}
              />

              <DateField
                name="approval_date"
                onChange={handleChange}
                label="DATUM VID GODKÄNNANDE"
                value={values.approval_date}
                error={errors && errors.approval_date}
                touched={touched.approval_date}
              />
            </>
          ) : null}

          {/*<SelectField name="member"
                                 options={yearList()}
                                 onChange={handleChange}
                                 label="Year"
                                 value={values.member}
                                 _label="name"
                                 _value="value"

                                 error={errors && errors.member}
                                 touched={touched.member}
                    />*/}

          <TextAreaField
            name="note"
            value={values.note}
            error={errors && errors.note}
            touched={touched.note}
            onChange={handleChange}
            label="Notering"
          />

          {/*<TextField name="second_address_line"
                               value={values.second_address_line}
                               error={errors && errors.second_address_line}
                               touched={touched.second_address_line}
                               onChange={handleChange}/>

                   <TextField name="company_name"
                               value={values.company_name}
                               error={errors && errors.company_name}
                               touched={touched.company_name}
                               onChange={handleChange}/>*/}

          <SelectField
            name="payment_status"
            options={paymentOptions}
            onChange={handleChange}
            label="Betalningsstatus"
            value={values.payment_status}
            _label="label"
            _value="value"
            error={errors && errors.payment_status}
            touched={touched.payment_status}
          />

          <SelectField
            name="role"
            options={roles}
            onChange={handleChange}
            label="Roll"
            value={values.role}
            _label="title"
            _value="slug"
            error={errors && errors.role}
            touched={touched.role}
          />

          <div className="text-center">
            <SaveButton processing={processing} type="submit" />
          </div>
          <UserFormWorker />
        </form>
      </div>
    </div>
  );
};

export default memo(UserForm);
