import React, {memo, useRef, useState} from "react";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import logoW from "../../../assets/images/more-option-icon.svg";
import expand from "../../../assets/images/close.svg";
import "./style.scss"
const ActionPopover = ({ popover,label, flagShow, className }) => {
  const [show, setShow] = useState(false);

  React.useEffect(() => {
    if (flagShow) setShow(false)
  }, [flagShow])
  
  const handleToggle = (nextShow) => {
    setShow(nextShow);
  };


  const handleOutsideClick = () => {
    setShow(false);
  };
  
    return (
      <OverlayTrigger
        placement="bottom-end"
        trigger="click"
        className={className}
        show={show}
        onToggle={handleToggle}
        rootClose={true} // Enables closing on outside click
        onHide={handleOutsideClick} // Ensures it closes
        overlay={popover} >
        {label ? label : <div className='popover-toggle-btn'><span><img src={show ? expand : logoW} /></span></div>}
        </OverlayTrigger>
        
      

    )
};

export default memo(ActionPopover)