
import getRoles from '../../../../ajax/actions/role/roles_get'

export function toggleCategoryListFilter(e)
{
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({type:'TOGGLE-CATEGORY-LIST-FILTER', name, value});
    dispatch( getRoles());
  }
}

