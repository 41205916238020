const DefaultCategoryListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    sort_column:  'id',
    sort_by: `desc`,
    q: ``,
  },
 
  fields: [
    { field: `id`, label: `ID`, sort: true },
    { field: `name`, label: `Name`, sort: true },
    
  ]
};

export const CategoryListState = { data: DefaultCategoryListState };