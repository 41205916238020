import { GroupFormState } from "./GroupForm-State";

export default function groupFormReducer(state = GroupFormState, action) {
  let { payload } = { ...state };
  let { groups } = action;
  switch (action.type) {
    case "SET-GROUP-DATA-FOR-UPDATE":
      payload = {
        name: groups.name,
        user_ids: JSON.parse(groups.user_ids),
        defaultSelectedUsers: groups.users,
      };
      return { ...state, payload };

    case "SET-DEFAULT-GROUP-FORM":
      payload = {
        name: ``,
        user_ids: [],
        defaultSelectedUsers: [],
      };
      return { ...state, payload };
    default:
      return state;
  }
}
