const { useEffect } = require("react");

const useMouseDown = ( func ) =>
{
  useEffect(() => {

    document.addEventListener("mousedown", func )
    return () => {
      document.removeEventListener("mousedown", func )
    };
  }, []);
}

export default useMouseDown