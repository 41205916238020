import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { loginToken } from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import store from "../../../store";
import getUsers from "./users_get";

function _success(success)
{
    return { type: 'APPROVE_USER_SUCCESS', success };
}
function _error(error)
{
    return { type: 'APPROVE_USER_ERROR', error };
}
function _processing(processing, id )
{
    return { type: 'APPROVE_USER_PROCESSING', processing, id };
}

function approveUser( id )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true, id ));
        dispatch(_success(null));
        dispatch(_error(null));

        axios({
            url: apiBaseUrl(`user/approve/${id}`),
            method: "post",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false, id ));
                handleSuccess( res );
                store.dispatch(getUsers());
            }).catch(function(error)
            {
                dispatch(_error(error));
                dispatch(_processing(false, id ));
                handleError( error );
            });
    }
}
export function approveUserReset()
{
    return dispatch => dispatch({ type: `APPROVE_USER_RESET` })
}

export default approveUser;