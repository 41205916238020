import React from 'react';
import {fewerLimitOptions} from '../../../../static-data/LimitOptions'
import { toggleCategoryListFilter } from './CategoryList-Actions'
import {connect, useDispatch, useSelector} from "react-redux";
import SelectField from "../../../common/form/SelectField";
import TextField from "../../../common/form/TextField";

const CategoryListFilters = () =>
{

    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { categoryListReducer} = reduxState;

    const toggleFilter = (e) =>
    {
        dispatch(toggleCategoryListFilter( e));
    };

        let {params} = categoryListReducer.data;
        return (
            <div className="row table-filter mb-5">
                <div className="col custom-col">
                        <SelectField name="limit"
                                          noFormGroup
                                          options={ fewerLimitOptions  }
                                          _value="total"
                                          _label="label"
                                          notSearchable
                                          value={ params.limit }
                                          placeholder="-- Limit --"
                                          onChange={ toggleFilter } 
                                          label="Begränsning"/>
                </div>
                <div className="col custom-col two-col-block">
                    <TextField name="q"
                               label="Sök"
                               placeholder="Nyckelord"
                               onChange={toggleFilter}
                               value={params.q}
                    />
                </div>
            </div>
        )
}
export default  CategoryListFilters;