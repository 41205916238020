import axios from "axios";
import { apiBaseUrl } from "../../../utils/URLUtils";
import { loginToken } from "../../../utils/LocalStorageUtils";
import { handleError, handleSuccess } from "../../../utils/ErrorUtils";
import getGroups from "./groups_get";
import store from "../../../store";

function _success(success) {
  return { type: "DELETE_GROUP_SUCCESS", success };
}

function _error(error) {
  return { type: "DELETE_GROUP_ERROR", error };
}

function _processing(processing, id) {
  return { type: "DELETE_GROUP_PROCESSING", processing, id };
}

function deleteGroup(id) {
  return (dispatch) => {
    dispatch(_processing(true, id));
    axios({
      url: apiBaseUrl(`group/${id}`),
      method: "delete",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + loginToken(),
      },
    })
      .then(function (res) {
        dispatch(_success(res));
        dispatch(_processing(false, id));
        handleSuccess(res);
        store.dispatch(getGroups());
      })
      .catch(function (error) {
        dispatch(_error(error));
        dispatch(_processing(false, id));
        handleError(error);
      });
  };
}
export default deleteGroup;
