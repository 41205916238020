import { cleanObject, emptyObject } from "./DataUtils";
import { TheApp } from "../Config";
import { ucFirstAll } from "./StringUtils";
let { Production } = TheApp.Engine;

export const apiBaseUrl = (endpoint) => {
  let url = !Production
    ? /*'https://oisfapi.keplerhosting.cloud/' :
      'https://oisfapi.keplerhosting.cloud/';*/
      "https://api.oisf.se/"
    : "https://api.oisf.se/";
  //   "https://oisfapistag.keplerhosting.cloud/"
  // : "https://oisfapistag.keplerhosting.cloud/";
  if (process.env.API_BASE_URL) {
    url = process.env.API_BASE_URL;
  }
  return endpoint ? url + endpoint : url;
};

export const basePath = (endpoint) => {
  let url = Production ? "https://api.oisf.se/" : "https://api.oisf.se/";
  //   `https://oisfapi.keplerhosting.cloud/`
  // : `https://oisfapi.keplerhosting.cloud/`;
  return endpoint ? url + endpoint : url;
};

export const URLPart = (_part) => {
  let part = document.URL.split(`/`);
  return part[part.length - _part];
};

export const endOfURL = () => lastItemInURL();

export const urlTrailArray = () =>
  urlTrailWithOutParams()
    .split(`/`)
    .filter((item) => item.length);
export const atHomePage = () => !urlTrailArray().length;
export const lastItemInURL = () => urlTrailArray()[urlTrailArray().length - 1];
export const secondToLastItemInURL = () =>
  urlTrailArray()[urlTrailArray().length - 2];

export const pageIconText = () => {
  if (!isNaN(lastItemInURL())) {
    return secondToLastItemInURL();
  } else {
    return lastItemInURL();
  }
};

export const iconText = (text) => {
  if (text[text.length - 1] === `s`) {
    return text.slice(0, -1);
  } else {
    return text;
  }
};

export const pushToPage = (history, path) => {
  history.push(path);
};

export const pushToPageWithData = (props, path, state) => {
  if (props && path) {
    props.history.push({ pathname: path, state });
  }
};

export const queryStringPresentInURL = () => {
  return !emptyObject(objectFromURLQuery());
};

export const urlParamValue = (item) => {
  item = item.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + item + "=([^&#]*)");
  let results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const toggleURLQuery = (name, value, history) => {
  let URLObj = objectFromURLQuery();
  if (value === `` && URLObj.hasOwnProperty(name)) {
    delete URLObj[name];
  } else {
    URLObj[name] = value;
  }
  buildURLFromObject(URLObj, history);
};

export const objectFromURLQuery = () => {
  let obj = {};
  let { URL } = document;
  if (URL.includes(`?`)) {
    let URLPart = URL.split(`?`);
    let queryPart = URLPart[1].split(`&`);
    queryPart.map((item) => {
      let itemPart = item.split(`=`);
      obj[itemPart[0]] = itemPart[1];
    });
  }
  return cleanObject(obj);
};

export const numberOfURLQueries = () => {
  return Object.keys(objectFromURLQuery()).length;
};

export const buildURLFromObject = (obj, history) => {
  if (obj) {
    if (emptyObject(obj))
      history && history.push && history.push(urlTrailWithOutParams());
    else {
      let URL = urlTrail();
      let newURL = ``;
      if (URL.includes(`?`)) {
        let part = URL.split(`?`);
        newURL = part[0];
      } else {
        newURL = URL;
      }
      newURL += `?`;
      Object.keys(obj).map((key) => (newURL += `${key}=${obj[key]}&`));
      if (newURL.endsWith(`&`)) {
        newURL = newURL.trim().slice(0, -1); // remove last & character
      }
      history && history.push && history.push(newURL);
    }
  }
};

export const urlTrail = () => {
  let trail = ``;
  let { URL } = document;
  let part = URL.trim().split(`//`);
  part[1].split(`/`).map((p, i) => {
    if (i) trail += `/${p}`;
  });
  return trail;
};

export const urlTrailWithOutParams = () => {
  if (!urlTrail().includes(`?`)) return urlTrail();
  let part = urlTrail().split(`?`);
  return part[0];
};

export const urlParamPresent = (key) => {
  return objectFromURLQuery().hasOwnProperty(key);
};

export const urlTrailWithoutLastItem = () => {
  let url = ``;
  urlTrailArray().map((item, i) => {
    if (i !== urlTrailArray().length - 1) {
      url += `/${item}`;
    }
  });
  return url;
};

export const keyIsLastItemInThisURLString = (trail, key) => {
  let trailArray = trail.split(`/`);
  return trailArray[trailArray.length - 1] === key;
};

export const keyIsLastItemInURL = (key) => {
  return urlTrailArray()[urlTrailArray().length - 1] === key;
};

export const linkFor = (key) => {
  let items = [];
  let link = ``;
  let theLink = ``;
  let count = urlTrailArray().length;
  urlTrailArray().map((u, i) => {
    if (count - 1 !== i) {
      link += `/${u}`;
      items.push(link);
    }
  });
  items.map((item) => {
    if (keyIsLastItemInThisURLString(item, key)) {
      theLink = item;
    }
  });
  return theLink;
};

export const activePageTitle = () => {
  let pageTitle = ``;
  if (!isNaN(lastItemInURL())) {
    // is number
    pageTitle = `${ucFirstAll(
      secondToLastItemInURL().slice(0, -1),
      `-`
    )} #${lastItemInURL()}`;
  } else pageTitle = ucFirstAll(lastItemInURL(), `-`);
  return pageTitle;
};

export const activateTab = (key, index) => {
  return urlTrailArray()[index] === key ? `tab-active` : null;
};

export const activateSiderMenuItem = (key, index) => {
  return urlTrailArray()[index] === key ? `item-selected` : null;
};

export const clearBuildUrls = (path) => {
  // return path;
  console.log(` <| path  |> `, path);
};

export const publicURL = process.env.PUBLIC_URL;
