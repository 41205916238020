import React, {useEffect} from 'react';
import loginUser from "../../../ajax/actions/user/user_login_post";
import {useDispatch, useSelector} from "react-redux"
import { loggedInUser } from '../../../utils/LocalStorageUtils';

const LoginFormWorker = () => {
    let dispatch = useDispatch();
    
    
    const {userLogin, loginForm, detailsUser, userOTPLoginReducer, userOTPLoginValidateReducer} = useSelector(state => ({
        userLogin: state.userLogin,
        loginForm: state.loginForm,
        detailsUser: state.detailsUser,
        userOTPLoginReducer: state.userOTPLoginReducer,
        userOTPLoginValidateReducer:state.userOTPLoginValidateReducer

    }));

    let {dispatchAPI} = loginForm.data;

    useEffect(() => {
        let {success,} = userLogin;
        if (success) {
            window.location.href = '/';
        }
    }, [userLogin.success])

    useEffect(() => {
        let {success,} = userOTPLoginReducer;
        if (success) {
            
            
            window.location.href = '/login-otp';
        }
    }, [userOTPLoginReducer.success])

    useEffect(() => {
        let {success,} = userOTPLoginValidateReducer;
        if (success) {
            
            
            loggedInUser(success.data);
            window.location.href = '/';
        }
    }, [userOTPLoginValidateReducer.success])

    useEffect(() => {
        if (dispatchAPI) {
            dispatch(loginUser());
        }
    }, [dispatchAPI])
    return null
}
export default LoginFormWorker;