import getGroups from "../../../../ajax/actions/group/groups_get";

export function toggleGroupListFilter(e) {
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({ type: "TOGGLE-GROUP-LIST-FILTER", name, value });
    dispatch(getGroups());
  };
}

export function toggleGroupFormModal(id) {
  return function (dispatch) {
    dispatch({ type: "TOGGLE-GROUP-FORM-MODAL", id });
  };
}
