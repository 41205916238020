import React from "react";
import { useDispatch, useSelector } from "react-redux";

import SaveButton from "../../../shared/buttons/SaveButton";
import TextField from "../../../shared/form/TextField";

import { useParams, useHistory } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";

import { endOfURL, urlTrailWithoutLastItem } from "../../../../utils/URLUtils";
import updateCategory, {
  updateCategoryReset,
} from "../../../../ajax/actions/category/category_put";
import createCategory, {
  createCategoryReset,
} from "../../../../ajax/actions/category/category_post";
import getCategory, {
  getCategoryReset,
} from "../../../../ajax/actions/category/category_get";

const CategoryForm = () => {
  const [payload, setPayload] = React.useState({
    name: "",
  });
  const reduxState = useSelector((state) => state);
  const { getCategoryReducer, postCategoryReducer, putCategoryReducer } =
    reduxState;
  let dispatch = useDispatch();

  let { id } = useParams();
  const history = useHistory();

  React.useEffect(() => {
    if (endOfURL() === "create") {
      setPayload({ name: "" });
    }
    return () => {
      dispatch(getCategoryReset());
    };
  }, []);

  React.useEffect(() => {
    if (postCategoryReducer.success) {
      dispatch(createCategoryReset());
      history.push(urlTrailWithoutLastItem());
    }
  }, [postCategoryReducer.success]);

  React.useEffect(() => {
    if (putCategoryReducer.success) {
      dispatch(updateCategoryReset());
      history.push("/category");
    }
  }, [putCategoryReducer.success]);

  React.useEffect(() => {
    if (id) {
      dispatch(getCategory(id));
    }
  }, [id]);

  React.useEffect(() => {
    if (getCategoryReducer.success) {
      const name = getCategoryReducer.success.data.message.name;
      setPayload({ name: name });
    }
  }, [getCategoryReducer.success]);

  const { handleSubmit, errors, touched, values, setFieldValue } = useFormik({
    initialValues: payload,
    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      name: Yup.string().required(` krävs`),
    }),

    onSubmit: (values) => {
      if (id) {
        dispatch(updateCategory(values, id));
      } else {
        dispatch(createCategory(values));
      }
    },
  });

  const onHandleChange = (e) => {
    setPayload({ [e.target.name]: e.target.value });
  };

  const processing = React.useMemo(() => {
    return (
      postCategoryReducer.processing ||
      putCategoryReducer.processing ||
      getCategoryReducer.processing
    );
  }, [postCategoryReducer, getCategoryReducer, putCategoryReducer]);

  return (
    <div className={processing.processing ? `item-disabled` : null}>
      <div className="offset-lg-1 col-lg-7 col-md-12">
        <h2>
          {endOfURL() === "create"
            ? "Skapa ny kategorier"
            : endOfURL() === "update"
            ? "Uppdatera kategorier"
            : ""}
        </h2>
        <form onSubmit={handleSubmit}>
          <TextField
            name="name"
            value={values.name}
            error={errors && errors.name}
            touched={touched.name}
            onChange={(e) => onHandleChange(e)}
            label="Name"
          />

          <div className="text-center">
            <SaveButton type="submit" processing={processing} />
          </div>
        </form>
      </div>
    </div>
  );
};
export default CategoryForm;
