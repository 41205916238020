import axios from 'axios'
import {apiBaseUrl} from "../../../../utils/URLUtils";
import {loginToken} from "../../../../utils/LocalStorageUtils";
import {handleError, handleSuccess} from "../../../../utils/ErrorUtils";
import getFolders from './folders_get';
import store from '../../../../store';


function _success(success) {
    return {type: 'DELETE_FOLDER_SUCCESS', success};
}

function _error(error) {
    return {type: 'DELETE_FOLDER_ERROR', error};
}

function _processing(processing) {
    return {type: 'DELETE_FOLDER_PROCESSING', processing};
}

function deleteFolder( id )
{
    return (dispatch) => {
        dispatch(_processing(true));
        
        axios({
            url:apiBaseUrl(`folder/${id}`),
            method:'delete',
            headers:{
                'Authorization': 'Bearer ' + loginToken()
            },
        }).then(function(res){
            dispatch(_success(res));
          dispatch(_processing(false));
          handleSuccess(res);
          // store.dispatch(getFolders())
        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });

    }
}

export default deleteFolder;